import { gql } from '@apollo/client';

export const UpdateCourseMutation = gql`
    mutation UpdateCourse($id: ID!, $item: OLCourseArgs!) {
        updateOLCourse(id: $id, item: $item) {
            id
            name
            test {
                questionsCount
                duration
            }
        }
    }
`;
