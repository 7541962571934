import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CourseEntity } from '@modules/courses/entities';
import { GetCourseQuery } from '@modules/courses/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetCourseQueryType, GetCourseQueryVariables } from '@modules/types/graphql';

const useCourse = (options?: QueryHookOptions<GetCourseQueryType, GetCourseQueryVariables>) => {
    const queryResult = useQuery<GetCourseQueryType, GetCourseQueryVariables>(GetCourseQuery, {
        fetchPolicy: 'network-only',
        ...options,
    });

    const course = useEntity(
        () => new CourseEntity(queryResult.data?.getOLCourse ?? null),
        [queryResult.data],
    );

    const result = {
        course,
        ...queryResult,
    };

    return result;
};

export { useCourse };
