import { PageFiltersDrawerProvider } from './page-filters-drawer-context';
import { PageFiltersProvider } from './page-filters-context';

type PageFiltersDrawerProps = {
    openDrawer?: boolean;
    onOpenDrawer?: () => void;
    onCloseDrawer?: () => void;
};

type PageFiltersBaseProps<T> = {
    queryFilters: Record<string, any>;
    initials: T;
    drawerFiltersKeys?: string[];
    disableAutoSetFilters?: boolean;
    onExecDefaultFilterCondition?: (search: string) => boolean;
};

type PageFiltersProps<T> = PageFiltersDrawerProps &
    PageFiltersBaseProps<T> & { children: React.ReactNode };

const PageFilters = <T extends Record<string, any>>(props: PageFiltersProps<T>) => {
    const { openDrawer, onOpenDrawer, onCloseDrawer, children, ...otherProps } = props;

    return (
        <PageFiltersDrawerProvider open={openDrawer} onOpen={onOpenDrawer} onClose={onCloseDrawer}>
            <PageFiltersProvider {...otherProps}>{children}</PageFiltersProvider>
        </PageFiltersDrawerProvider>
    );
};

export { PageFilters };
