import { BaseEntity, Entity as DecribeEntity, Relation, RelationList } from '@lib/entity';

import { FileInfoEntity } from '@modules/shared/entities';
import { StudentProfileSectionEntity } from './student-profile-section.entity';
import { StudentProfileSectionEntityList } from './student-profile-section.entity-list';

import type { StudentProfile } from '@modules/types/graphql';

export type Entity = StudentProfile | null;

@DecribeEntity('StudentProfileEntity')
class StudentProfileEntity extends BaseEntity {
    id: string;
    fullName: string;
    qrCodeUrl: string;

    @Relation(() => FileInfoEntity)
    photo: FileInfoEntity;

    @RelationList(() => StudentProfileSectionEntityList)
    sections: StudentProfileSectionEntity[];

    constructor(student: Entity) {
        super(student);

        this.id = student?.id ?? '';
        this.fullName = student?.fullName ?? '';
        this.qrCodeUrl = student?.qrCodeUrl ?? '';
    }
}

export { StudentProfileEntity };
