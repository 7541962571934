import { useLazyQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { LapEntityList } from '@modules/lap/entities';
import { GetAccessLapsQuery } from '@modules/lap/graphql';

import type { QueryLazyOptions, LazyQueryResult, QueryHookOptions } from '@apollo/client';
import type { LapEntity } from '@modules/lap/entities';
import type { GetAccessLapsQueryType, GetAccessLapsQueryVariables } from '@modules/types/graphql';

type Tuple = [
    (options?: QueryLazyOptions<GetAccessLapsQueryVariables>) => void,
    LazyQueryResult<GetAccessLapsQueryType, GetAccessLapsQueryVariables> & {
        laps: LapEntity[];
        count: number;
    },
];

const useLazyAccessLaps = (
    options?: QueryHookOptions<GetAccessLapsQueryType, GetAccessLapsQueryVariables>,
): Tuple => {
    const [getAccessLaps, queryResult] = useLazyQuery<
        GetAccessLapsQueryType,
        GetAccessLapsQueryVariables
    >(GetAccessLapsQuery, options);

    const { entities: laps, count } = useEntity(
        () => new LapEntityList(queryResult.data?.getAccessLaps ?? []),
        [queryResult.data],
    );

    return [getAccessLaps, { laps, count, ...queryResult }];
};

export { useLazyAccessLaps };
