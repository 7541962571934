import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/client';

import { routes } from '@config/routes';
import { useNavigate } from '@lib/routing';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ExtendedFormik } from '@modules/layout/organisms';
import { useCurrentLap } from '@modules/lap/hooks';
import { SetCurrentLapMutation } from '@modules/lap/graphql';
import { ChangeLapForm } from './change-lap-form';

import type {
    SetCurrentLapMutationType,
    SetCurrentLapMutationVariables,
} from '@modules/types/graphql';

type ChangeLapFormikProps = {
    onCancel: () => void;
};

const ChangeLapFormik = (props: ChangeLapFormikProps): React.ReactElement => {
    const { onCancel } = props;

    const { t } = useTranslation(['errors', 'lap']);

    const navigate = useNavigate();
    const apolloClient = useApolloClient();

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { currentLap } = useCurrentLap();

    const [setCurrentLap] = useMutation<SetCurrentLapMutationType, SetCurrentLapMutationVariables>(
        SetCurrentLapMutation,
    );

    const initialValues = {
        course: currentLap.course.exists() ? currentLap.course : null,
        lap: currentLap,
    };

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={yup.object({
                course: yup.mixed().required(t('errors:validations.required') ?? ''),
                lap: yup.mixed().required(t('errors:validations.required') ?? ''),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    if (!values.lap) {
                        return;
                    }

                    const { data: setCurrentLapData } = await setCurrentLap({
                        variables: { id: values.lap.id },
                    });

                    if (setCurrentLapData?.setCurrentLap) {
                        enqueueSuccess(t('lap:notifiers.change_lap.success'));

                        apolloClient.resetStore();
                        navigate(routes.dashboard.path);
                        onCancel();
                    } else {
                        enqueueError(t('lap:notifiers.change_lap.error'));
                    }
                } catch (e) {
                    throw e;
                }
            }}
        >
            {() => <ChangeLapForm onCancel={onCancel} />}
        </ExtendedFormik>
    );
};

export { ChangeLapFormik };
