import { StringHelper } from '@helpers';

type Payload = {
    orderBy?: string;
    orderField?: string | string[];
    orderByWith?: string;
};

const getColumnFilterId = (payload: Payload): string => {
    let orderFieldParts: string[] = [];
    let orderByWithString: string = '';

    if (payload.orderBy) {
        orderFieldParts = [payload.orderBy];
    }

    if (payload.orderField) {
        if (Array.isArray(payload.orderField)) {
            orderFieldParts = [...orderFieldParts, ...payload.orderField];
        } else {
            orderFieldParts = [...orderFieldParts, payload.orderField];
        }
    }

    if (payload.orderByWith) {
        const orderByWithEntries = Object.entries(JSON.parse(payload.orderByWith));

        orderByWithString = orderByWithEntries.reduce(
            (carry, [key, item]) => carry + `-${key}${item}`,
            orderByWithString,
        );
    }

    const result = [...orderFieldParts, orderByWithString]
        .map((string, index) => (index !== 0 ? StringHelper.capitalizeFirstLetter(string) : string))
        .join('');

    return result;
};

export { getColumnFilterId };
