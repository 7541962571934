import { format } from 'date-fns';

import { CommonHelper } from '@helpers';
import { BaseEntity, Entity as DecribeEntity, Relation, RelationList } from '@lib/entity';
import { i18n } from '@lib/i18n';
import { RegionEntity, LocationEntity } from '@modules/shared/entities';
import { UserEntity } from '@modules/user/entities';
import { LapAccessEntityList } from '@modules/lap/entities';
import { SchoolEntity } from '@modules/schools/entities';
import {
    performanceManagmentTranslates,
    creditOfPointTranslates,
    interestTranslates,
} from '@modules/students/constants';
import { Gender } from '@modules/types/graphql';
import { SocialsEntity } from './socials-entity';
import { IntegrationEntity } from './integration-entity';

import type { UserEntityType } from '@modules/user/entities';
import type { LapAccessEntity } from '@modules/lap/entities';
import type { SchoolEntityType } from '@modules/schools/entities';
import type { Student, PerformanceManagement, CreditOfPoint } from '@modules/types/graphql';
import type { Entity as SocialsEntityType } from './socials-entity';

export type Entity =
    | (Partial<Omit<Student, 'school' | 'attachedUser'>> & {
          school?: SchoolEntityType;
          attachedUser?: UserEntityType;
          socials?: SocialsEntityType;
      })
    | null;

@DecribeEntity('StudentEntity')
class StudentEntity extends BaseEntity {
    id: string;
    fullName: string;
    birthdate: any;
    address: string;
    phone: string;
    email: string;
    gender: Gender | string;
    accessSentAt: string;
    isOnline: boolean;
    isAccessSent: boolean;
    accessSentStatus: string;
    accessSentStatusText: string;
    performanceManagement: (PerformanceManagement | null)[];
    creditOfPoints: (CreditOfPoint | null)[];
    interest: (string | null)[];
    schoolClass: string;
    schoolClassProfile: string;
    facultative: string;
    clubs: string;
    sportSection: string;
    sportsCategory: string;
    qrCodeUrl: string;

    @Relation(() => RegionEntity)
    region: RegionEntity;

    @Relation(() => LocationEntity)
    location: LocationEntity;

    @Relation(() => SchoolEntity)
    school: SchoolEntity;

    @Relation(() => UserEntity)
    attachedUser: UserEntity;

    @Relation(() => SocialsEntity)
    socials: SocialsEntity;

    @RelationList(() => LapAccessEntityList)
    lapAccessList: (LapAccessEntity | null)[];

    @RelationList(() => LapAccessEntityList)
    schoolLapAccessList: (LapAccessEntity | null)[];

    @Relation(() => IntegrationEntity)
    integration: IntegrationEntity;

    constructor(student: Entity) {
        super(student);

        this.id = student?.id ?? '';
        this.fullName = student?.fullName ?? '';
        this.birthdate = CommonHelper.shapeDate(student?.birthdate);
        this.address = student?.address ?? '';
        this.phone = student?.phone ?? '';
        this.email = student?.email ?? '';
        this.accessSentAt = student?.accessSentAt ?? '';
        this.isOnline = student?.isOnline ?? false;
        this.isAccessSent = student?.isAccessSent ?? false;
        this.accessSentStatus = student?.accessSentStatus ?? '';
        this.accessSentStatusText = student?.accessSentStatusText ?? '';
        this.performanceManagement = student?.performanceManagement ?? [];
        this.creditOfPoints = student?.creditOfPoints ?? [];
        this.interest = student?.interest ?? [];
        this.schoolClass = student?.schoolClass ?? '';
        this.schoolClassProfile = student?.schoolClassProfile ?? '';
        this.facultative = student?.facultative ?? '';
        this.clubs = student?.clubs ?? '';
        this.sportSection = student?.sportSection ?? '';
        this.sportsCategory = student?.sportsCategory ?? '';
        this.qrCodeUrl = student?.qrCodeUrl ?? '';

        if (!student?.gender || student?.gender === Gender.nogender) {
            this.gender = '';
        } else {
            this.gender = student.gender;
        }
    }

    preparePerfomanceManagmentForForm() {
        const result = this.prepareEnumForForm(
            this.performanceManagement,
            performanceManagmentTranslates,
        );

        return result;
    }

    prepareCreditOfPointForForm() {
        const result = this.prepareEnumForForm(this.creditOfPoints, creditOfPointTranslates);

        return result;
    }

    prepareInterestForForm() {
        const result = this.prepareEnumForForm(this.interest, interestTranslates);

        return result;
    }

    prepareEnumForForm(array: any[], translates: Record<string, any>): AutocompleteOption<any>[] {
        const hasValues = array.filter(Boolean).length !== 0;

        if (!hasValues) {
            return [];
        }

        const result = array.map(value => ({
            key: value,
            value: translates[value],
        }));

        return result;
    }

    getFieldOrDefaultValue(field: keyof StudentEntity, defaultValue: string | number = '-') {
        if (!this[field]) {
            return defaultValue;
        }

        return this[field];
    }

    getFormattedAccessSentDate() {
        if (this.accessSentAt === null) {
            return '-';
        }

        try {
            const validDate = new Date(this.accessSentAt);
            const date = format(validDate, 'dd.MM.yyyy');
            const time = format(validDate, 'HH:mm:ss');

            const formatted = i18n.t('common:blocks.time.date_to_minutes', { date, time });

            return formatted;
        } catch {
            return '-';
        }
    }

    getFormattedBirthdate() {
        if (!this.birthdate) {
            return '-';
        }

        try {
            const date = format(new Date(this.birthdate), 'dd.MM.yyy');

            return date;
        } catch {
            return '-';
        }
    }

    getUniqueLapAccessList() {
        const uniqueLapAccessList = this.schoolLapAccessList
            .filter(
                schoolLapAccess =>
                    !this.lapAccessList?.find(
                        lapAccess => lapAccess?.lap?.id === schoolLapAccess?.lap?.id,
                    ),
            )
            ?.map(lapAccess => lapAccess?.lap ?? null);

        return uniqueLapAccessList ?? [];
    }
}

export { StudentEntity };
