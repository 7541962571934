import { tablePerPage } from '@modules/layout/constants/table';
import { useLocationQuery } from './use-location-query';

import type { Sort } from '@modules/types/graphql';
import type { LocationQuery } from './use-location-query';

export type TableFiltersQuery = {
    filters: {
        page: number;
        perPage: number;
        orderBy?: string;
        orderDirection?: Sort;
        orderField?: string | string[];
        orderByWith?: string;
    };
    query: LocationQuery[0];
    setQuery: LocationQuery[1];
};

const useTableFiltersQuery = (): TableFiltersQuery => {
    const [query, setQuery] = useLocationQuery();

    const result = {
        filters: {
            page: query.page ? parseInt(query.page as string, 10) : 1,
            perPage: query.perPage ? parseInt(query.perPage as string, 10) : tablePerPage[1].value,
            orderBy: query.orderBy ? (query.orderBy as string) : undefined,
            orderField: query.orderField ?? undefined,
            orderDirection: query.orderDirection ? (query.orderDirection as Sort) : undefined,
            orderByWith: query.orderByWith ? (query.orderByWith as string) : undefined,
        },
        query,
        setQuery,
    };

    return result;
};

export { useTableFiltersQuery };
