import React from 'react';
import { usePrevious } from 'react-use';
import { useLocation } from '@reach/router';

import { createJivoWidget, clearJivoWidget } from '@lib/jivosite';
import { JIVO_ID } from '@config/environment';
import { useJivositeInfo } from '@modules/auth/hooks';
import { useCurrentUser } from '@modules/user/hooks';

const AvangardJivoWidget = (): null => {
    const location = useLocation();
    const isEljurPage = location.pathname.includes('eljur');
    const isPatriotDictationPage = location.pathname.includes('patriot-dictation');

    const { currentUser } = useCurrentUser({ fetchPolicy: 'cache-only' });
    const previousCurrentUser = usePrevious(currentUser);

    const { jivoInfo, refetch: jivoInfoRefetch } = useJivositeInfo({
        // For eljur's pages we have disable jivosite
        skip: isEljurPage || isPatriotDictationPage,
    });

    React.useEffect(() => {
        // For eljur's pages we have disable jivosite
        if (isEljurPage || isPatriotDictationPage) {
            return;
        }

        // TODO: hotfix, need to include to creatJivoWidget
        localStorage.setItem('jivoInfo', JSON.stringify(jivoInfo));

        if (previousCurrentUser?.id === currentUser.id) {
            return;
        }

        if (
            !currentUser.authenticated() ||
            currentUser.isSuperAdmin() ||
            currentUser.isAdmin() ||
            currentUser.isEmployee()
        ) {
            jivoInfoRefetch();
            clearJivoWidget(JIVO_ID);
        } else {
            createJivoWidget(JIVO_ID);
        }
    }, [currentUser, jivoInfo, location.pathname]);

    return null;
};

export { AvangardJivoWidget };
