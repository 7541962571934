import { gql } from '@apollo/client';

import { LapFragment } from './lap';

export const LapAccessFragment = gql`
    fragment LapAccess on LapAccess {
        id
        blocked
        target
        targetId
        lap {
            ...Lap
        }
        startAt
        finishAt
        studentCount
    }
    ${LapFragment}
`;
