import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakpoints } from '@avangard/ui/utils';

import { lockBodyScroll, unlockBodyScroll } from '@modules/layout/helpers';
import { Header, Sidebar } from '@modules/layout/organisms';
import { BaseLayoutProvider } from './context';

type BaseLayoutProps = {
    pageTitle: string;
    children: React.ReactNode;
    withBackIcon?: boolean;
};

const Root = styled.main`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
`;

const Main = styled.section`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding-inline-start: 245px;

    ${breakpoints.down('sm')} {
        padding-top: 56px;
        padding-inline-start: 0;
    }
`;

const Container = styled.div<{ dense?: boolean }>`
    display: flex;
    width: 100%;
    min-height: calc(100% - 120px);
    padding: 16px 32px;

    ${breakpoints.down('xs')} {
        padding: 16px;
        height: 100%;
    }
`;

const BaseLayout = (props: BaseLayoutProps): React.ReactElement => {
    const { pageTitle: pageTitleProps, withBackIcon, children } = props;

    const { t } = useTranslation('common');

    const [openSidebar, setOpenSidebar] = React.useState<boolean>(false);

    const handleOpenSidebar = (): void => {
        lockBodyScroll();
        setOpenSidebar(true);
    };

    const handleCloseSidebar = (): void => {
        unlockBodyScroll();
        setOpenSidebar(false);
    };

    const handleClickContainer = (): void => {
        if (openSidebar) {
            handleCloseSidebar();
        }
    };

    return (
        <BaseLayoutProvider pageTitle={pageTitleProps ? t(pageTitleProps) : ''}>
            <Root>
                <Sidebar open={openSidebar} onClose={handleCloseSidebar} />

                <Main>
                    <Header
                        withBackIcon={withBackIcon}
                        openSidebar={openSidebar}
                        onOpenSidebar={handleOpenSidebar}
                        onCloseSidebar={handleCloseSidebar}
                    />
                    <Container onClick={handleClickContainer}>{children}</Container>
                </Main>
            </Root>
        </BaseLayoutProvider>
    );
};

export { BaseLayout };
