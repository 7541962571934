import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Maybe, JivositeInfo } from '@modules/types/graphql';

export type Entity = Maybe<JivositeInfo>;

type JivositeContactInfo = {
    email: string;
    name: string;
    phone: string;
};

type JivositeCustomData = {
    content: string;
    title: string;
};

@DecribeEntity('JivositeEntity')
class JivositeEntity extends BaseEntity {
    contactInfo: Maybe<JivositeContactInfo>;
    customData: Maybe<JivositeCustomData>[];
    userToken: string;

    constructor(jivoInfo: Entity) {
        super(jivoInfo);

        this.contactInfo = jivoInfo?.contactInfo ? jivoInfo.contactInfo : null;
        this.customData = jivoInfo?.customData ? jivoInfo.customData : [];
        this.userToken = jivoInfo?.userToken ?? '';
    }

    exists() {
        const exists = this.userToken !== '';

        return exists;
    }
}

export { JivositeEntity };
