import React from 'react';
import styled from 'styled-components';
import AvatarEditor, { Position } from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Remove, Add, RotateLeft, RotateRight } from '@material-ui/icons';
import { makeStyles, withStyles, IconButton, Slider } from '@material-ui/core';
import { Button, Modal } from '@avangard/ui/core';

import { createFileLink } from '@lib/routing';
import { UpdateFileCropMutation } from '@modules/shared/graphql';
import { UpdateFileCropRequest } from '@modules/shared/requests';
import { useEnqueueStacks } from '@modules/layout/hooks';

import type { ModalProps } from '@avangard/ui/core';
import type { ImageCropInfoEntity } from '@modules/shared/entities';
import type {
    UpdateFileCropMutationType,
    UpdateFileCropMutationVariables,
} from '@modules/types/graphql';

type AvatarEditorModalProps = Omit<ModalProps, 'title' | 'children'> & {
    photoId: string;
    cropInfo: ImageCropInfoEntity;
    onAccept?: (croppedThumbnailUrl?: string | null) => void;
};

const useModalStyles = makeStyles({
    container: {
        maxWidth: 400,
        width: '100%',
    },
});

const StyledSlider = withStyles({
    root: {
        height: 8,
    },
    rail: {
        height: 8,
    },
    track: {
        height: 4,
        marginTop: 2,
    },
    thumb: {
        width: 20,
        height: 20,
        marginTop: -6,
        marginInlineEnd: -10,
    },
})(Slider);

const Body = styled.div`
    width: 100%;
`;

const Actions = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 24px;

    > * {
        &:not(:last-of-type) {
            margin-inline-end: 8px;
        }
    }
`;

const Rotate = styled.div`
    display: flex;
    align-items: center;
    margin-top: -12px;
    margin-bottom: 12px;
`;

const RotateActions = styled.div`
    margin-inline-start: auto;
    margin-inline-end: -6px;

    & > * {
        &:not(:last-child) {
            margin-inline-end: 4px;
        }
    }
`;

const Title = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0.4px;
`;

const Scale = styled.div`
    display: flex;
    align-items: center;
    margin-top: 12px;

    & > * {
        &:first-child {
            flex: 0 0 auto;
            margin-inline-start: -8px;
        }

        &:nth-child(2) {
            max-width: 82%;
            margin: auto;
        }

        &:last-child {
            flex: 0 0 auto;
            margin-inline-end: -8px;
        }
    }
`;

const StyledIconButton = styled(IconButton)`
    padding: 4px;
`;

const rotateLeftIcon = <RotateLeft style={{ fontSize: 20 }} />;
const rotateRightIcon = <RotateRight style={{ fontSize: 20 }} />;
const removeIcon = <Remove style={{ fontSize: 20 }} />;
const addIcon = <Add style={{ fontSize: 20 }} />;

const AvatarEditorModal = (props: AvatarEditorModalProps): React.ReactElement | null => {
    const { cropInfo, photoId, onClose, onAccept, ...otherProps } = props;

    const { t } = useTranslation();

    const modalClasses = useModalStyles();
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const editorRef = React.useRef<any>(null);
    const options = cropInfo.getAvatarEditorValues();

    const [rotate, setRotate] = React.useState<number>(options.rotate);

    const handlerRotateLeft = (): void => setRotate(rotate - 90);
    const handlerRotateRight = (): void => setRotate(rotate + 90);

    const [scale, setScale] = React.useState<number>(options.scale);

    const handlerScaleChange = (_: React.ChangeEvent<{}>, value: number | number[]): void =>
        setScale(+value);

    const handlerScaleRemove = (): void => setScale(scale - 0.1);
    const handlerScaleAdd = (): void => setScale(scale + 0.1);

    const [position, setPosition] = React.useState<Position>(options.position);

    const handlerPositionChange = (changedPosition: Position): void => setPosition(changedPosition);

    const [updateFileCrop, { loading: updateFileCropLoading }] = useMutation<
        UpdateFileCropMutationType,
        UpdateFileCropMutationVariables
    >(UpdateFileCropMutation);

    const handleClickUpdateFileCrop = async (): Promise<void> => {
        try {
            if (!editorRef.current) {
                return;
            }

            const values = editorRef.current.getCroppingRect();
            const variables = new UpdateFileCropRequest({ ...values, rotate, scale }, { photoId });

            const { data: updateFileCropData } = await updateFileCrop({ variables });

            const croppedThumbnail = updateFileCropData?.updateFileCrop?.croppedThumbnail;

            if (croppedThumbnail) {
                enqueueSuccess(t('notifiers:avatar.success'));
                onClose();

                onAccept?.(croppedThumbnail);
            } else {
                enqueueError(t('notifiers:avatar.error'));
            }
        } catch (e) {
            enqueueError(e);
        }
    };

    const baseAvatarEditorProps = React.useMemo(
        () => ({
            ref: editorRef,
            image: createFileLink({ path: photoId }),
            onPositionChange: handlerPositionChange,
            borderRadius: 100,
            border: 30,
            style: {
                width: '100%',
                height: 'auto',
            },
        }),
        [photoId],
    );

    const avatarEditorProps = {
        ...baseAvatarEditorProps,
        rotate,
        scale,
        position,
    };

    return (
        <Modal
            initialWidth
            title={t('common:blocks.avatar.edit') ?? ''}
            classes={modalClasses}
            onClose={onClose}
            {...otherProps}
        >
            <Body>
                <Rotate>
                    <Title>{t('common:blocks.avatar.choose_section')}</Title>

                    <RotateActions>
                        <StyledIconButton onClick={handlerRotateLeft}>
                            {rotateLeftIcon}
                        </StyledIconButton>
                        <StyledIconButton onClick={handlerRotateRight}>
                            {rotateRightIcon}
                        </StyledIconButton>
                    </RotateActions>
                </Rotate>

                <AvatarEditor {...avatarEditorProps} />

                <Scale>
                    <StyledIconButton onClick={handlerScaleRemove} disabled={scale <= 1.0}>
                        {removeIcon}
                    </StyledIconButton>

                    <StyledSlider
                        min={1}
                        max={3}
                        step={0.1}
                        value={scale}
                        onChange={handlerScaleChange}
                    />

                    <StyledIconButton onClick={handlerScaleAdd} disabled={scale >= 3}>
                        {addIcon}
                    </StyledIconButton>
                </Scale>
            </Body>

            <Actions>
                <Button variant='outlined' disabled={updateFileCropLoading} onClick={onClose}>
                    {t('common:actions.cancel')}
                </Button>

                <Button loading={updateFileCropLoading} onClick={handleClickUpdateFileCrop}>
                    {t('common:actions.save')}
                </Button>
            </Actions>
        </Modal>
    );
};

export { AvatarEditorModal };
