import { EntityList } from '@lib/entity';
import { LapWithCertificateEntity } from './lap-with-certificate-entity';

import type { LapWithCertificateRawEntity } from './lap-with-certificate-entity';

class LapWithCertificateEntityList extends EntityList<LapWithCertificateEntity> {
    constructor(items: LapWithCertificateRawEntity[] | null, count?: number | null) {
        super(LapWithCertificateEntity, items, count);
    }
}

export { LapWithCertificateEntityList };
