import { useLazyQuery } from '@apollo/client';

import { GetStudentSchoolClassesQuery } from '@modules/shared/graphql';

import type { QueryLazyOptions, LazyQueryResult, QueryHookOptions } from '@apollo/client';
import type {
    GetStudentSchoolClassesQueryType,
    GetStudentSchoolClassesQueryVariables,
} from '@modules/types/graphql';

type Tuple = [
    (options?: QueryLazyOptions<GetStudentSchoolClassesQueryVariables>) => void,
    LazyQueryResult<GetStudentSchoolClassesQueryType, GetStudentSchoolClassesQueryVariables> & {
        schoolClasses: (string | null)[];
    },
];

const useLazyStudentSchoolClasses = (
    options?: QueryHookOptions<
        GetStudentSchoolClassesQueryType,
        GetStudentSchoolClassesQueryVariables
    >,
): Tuple => {
    const [getStudentSchoolClasses, queryResult] = useLazyQuery<
        GetStudentSchoolClassesQueryType,
        GetStudentSchoolClassesQueryVariables
    >(GetStudentSchoolClassesQuery, options);

    const schoolClasses = queryResult.data?.getStudentSchoolClasses ?? [];

    return [getStudentSchoolClasses, { schoolClasses, ...queryResult }];
};

export { useLazyStudentSchoolClasses };
