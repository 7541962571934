import { gql } from '@apollo/client';

import { LapFragment } from '../fragments';

export const GetLapsQuery = gql`
    query GetLaps($platform: Platform, $courseId: ID) {
        getLaps(platform: $platform, courseId: $courseId) {
            ...Lap
        }
    }
    ${LapFragment}
`;
