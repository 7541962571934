import { useLazyQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { LapEntityList } from '@modules/lap/entities';
import { GetLapsQuery } from '@modules/lap/graphql';

import type { QueryLazyOptions, LazyQueryResult, QueryHookOptions } from '@apollo/client';
import type { LapEntity } from '@modules/lap/entities';
import type { GetLapsQueryType, GetLapsQueryVariables } from '@modules/types/graphql';

type Tuple = [
    (options?: QueryLazyOptions<GetLapsQueryVariables>) => void,
    LazyQueryResult<GetLapsQueryType, GetLapsQueryVariables> & {
        laps: LapEntity[];
        count: number;
    },
];

const useLazyLaps = (
    options?: QueryHookOptions<GetLapsQueryType, GetLapsQueryVariables>,
): Tuple => {
    const [getLaps, queryResult] = useLazyQuery<GetLapsQueryType, GetLapsQueryVariables>(
        GetLapsQuery,
        options,
    );

    const { entities: laps, count } = useEntity(
        () => new LapEntityList(queryResult.data?.getLaps ?? []),
        [queryResult.data],
    );

    return [getLaps, { laps, count, ...queryResult }];
};

export { useLazyLaps };
