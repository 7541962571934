import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { LapEntity } from '@modules/lap/entities';
import { GetCurrentLapQuery } from '@modules/lap/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetCurrentLapQueryType } from '@modules/types/graphql';

const useCurrentLap = (options?: QueryHookOptions<GetCurrentLapQueryType>) => {
    const queryResult = useQuery<GetCurrentLapQueryType>(GetCurrentLapQuery, options);

    const currentLap = useEntity(
        () => new LapEntity(queryResult.data?.getCurrentLap ?? null),
        [queryResult.data],
    );

    const result = {
        currentLap,
        ...queryResult,
    };

    return result;
};

export { useCurrentLap };
