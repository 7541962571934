import { Gender } from '@modules/types/graphql';

export const genderTranslates = {
    [Gender.nogender]: 'common:blocks.gender.nogender',
    [Gender.male]: 'common:blocks.gender.male',
    [Gender.female]: 'common:blocks.gender.female',
};

export const genders = Object.entries(genderTranslates).map(([key, value]) => ({
    key,
    value,
}));

export const gendersOptions = Object.entries(genderTranslates)
    .filter(([key]) => ![Gender.nogender].includes(key as Gender))
    .map(([key, value]) => ({
        key,
        value,
    }));
