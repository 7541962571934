import { gql } from '@apollo/client';

import { LapFragment } from '../fragments';

export const UpdateLapMutation = gql`
    mutation UpdateLap($id: ID!, $item: LapUpdateArgs!) {
        updateLap(id: $id, item: $item) {
            ...Lap
        }
    }
    ${LapFragment}
`;
