import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@avangard/ui/core';

import { ChangePasswordFormModal } from '@modules/auth/organisms';

const ChangePasswordButton = (): React.ReactElement => {
    const { t } = useTranslation(['common']);

    const [openChangePasswordFormModal, setChangePasswordFormModal] = React.useState(false);

    const handleOpenChangePasswordFormModal = (): void => setChangePasswordFormModal(true);
    const handleCloseChangePasswordFormModal = (): void => setChangePasswordFormModal(false);

    return (
        <>
            <Button variant='outlined' onClick={handleOpenChangePasswordFormModal}>
                {t('common:actions.change')}
            </Button>

            <ChangePasswordFormModal
                open={openChangePasswordFormModal}
                onClose={handleCloseChangePasswordFormModal}
            />
        </>
    );
};

export { ChangePasswordButton };
