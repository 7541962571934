import { useTranslation } from 'react-i18next';
import { Modal } from '@avangard/ui/core';

import { LapAccessUpdateForm } from '@modules/lap/organisms';

import type { ModalProps } from '@avangard/ui/core';
import type { LapAccessUpdateFormProps } from '@modules/lap/organisms';

type LapAccessUpdateFormModalProps = Omit<ModalProps, 'title' | 'children'> &
    Omit<LapAccessUpdateFormProps, 'onCancel'>;

const LapAccessUpdateFormModal = (props: LapAccessUpdateFormModalProps) => {
    const { lapAccess, onClose, ...otherProps } = props;

    const { t } = useTranslation(['access']);

    return (
        <Modal title={t('access:actions.edit_access') ?? ''} onClose={onClose} {...otherProps}>
            <LapAccessUpdateForm lapAccess={lapAccess} onCancel={onClose} />
        </Modal>
    );
};

export { LapAccessUpdateFormModal };
