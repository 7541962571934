import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Checkbox, withStyles, FormGroup, FormControlLabel } from '@material-ui/core';
import {
    Autocomplete,
    Button,
    DatepickerSinglePicker,
    DatepickerTextField,
    OnDateChangeProps,
} from '@avangard/ui/core';

import { useEnqueueStacks } from '@modules/layout/hooks';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { useCurrentUser } from '@modules/user/hooks';
import { useCourses } from '@modules/courses/hooks';
import { useLapsTableFiltersQuery } from '@modules/lap/hooks';
import { CreateLapRequest, LapCollectionRequest } from '@modules/lap/requests';
import { CreateLapMutation, GetLapCollectionQuery } from '@modules/lap/graphql';
import { UserGrantActionToken, UserGrantCategoryToken } from '@modules/types/graphql';

import type { CourseEntity } from '@modules/courses/entities';
import type { CreateLapFormValues } from '@modules/lap/requests';
import type { CreateLapMutationType, CreateLapMutationVariables } from '@modules/types/graphql';

type LapCreateFormProps = {
    onCancel: () => void;
};

const StyledFormGroup = withStyles({
    root: {
        marginTop: -8,
        marginInlineEnd: -4,
        marginBottom: '12px!important',

        '& > *': {
            width: '100%',
        },
    },
})(FormGroup);

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 24px;
        }
    }
`;

const FormRowSubmit = styled(FormStyled.FormRowSubmit)`
    justify-content: flex-end;
`;

const LapCreateForm = (props: LapCreateFormProps) => {
    const { onCancel } = props;

    const { t } = useTranslation(['common', 'errors', 'lap']);

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { currentUser } = useCurrentUser();
    const { lapGrants } = currentUser.can({
        [UserGrantCategoryToken.lap]: [UserGrantActionToken.list],
    });

    const { filters } = useLapsTableFiltersQuery();
    const args = new LapCollectionRequest(filters);

    const { courses, loading: coursesLoading } = useCourses();

    const [createLap] = useMutation<CreateLapMutationType, CreateLapMutationVariables>(
        CreateLapMutation,
    );

    const initialValues: CreateLapFormValues = {
        course: null,
        from: null,
        to: null,
        isFree: false,
        canCertificate: false,
    };

    return (
        <ExtendedFormik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                course: yup.mixed().required(t('errors:validations.required') ?? ''),
                from: yup
                    .date()
                    .nullable()
                    .required(t('errors:validations.required') ?? ''),
                to: yup
                    .date()
                    .nullable()
                    .required(t('errors:validations.required') ?? '')
                    .min(yup.ref('from'), t('errors:time.invalid_range') ?? ''),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    let options: Parameters<typeof createLap>[0] = {
                        variables: {
                            item: new CreateLapRequest(values),
                        },
                    };

                    if (lapGrants.canList) {
                        options = {
                            ...options,
                            refetchQueries: [{ query: GetLapCollectionQuery, variables: { args } }],
                            awaitRefetchQueries: true,
                        };
                    }

                    const { data: createLapData } = await createLap(options);

                    const lapId = createLapData?.createLap?.id;

                    if (lapId) {
                        enqueueSuccess(t('lap:notifiers.add_lap.success'));
                        onCancel();
                    } else {
                        enqueueError(t('lap:notifiers.add_lap.error'));
                    }
                } catch (e) {
                    console.log(e);
                    throw e;
                }
            }}
        >
            {formikProps => {
                const { values, errors, isSubmitting, setFieldValue, handleChange } = formikProps;

                const handleChangeDate = (field: string) => (data: OnDateChangeProps) =>
                    setFieldValue(field, data.date);

                const handleChangeAutocompleteField =
                    <T extends any>(field: string) =>
                    (_: React.ChangeEvent<{}>, value: T | null) =>
                        setFieldValue(field, value);

                return (
                    <StyledForm>
                        <Autocomplete<CourseEntity | null, false, false, false>
                            fullWidth
                            required
                            id='course'
                            label={t('common:forms.labels.course') ?? ''}
                            placeholder={t('common:forms.placeholder.choose_course') ?? ''}
                            options={courses}
                            value={values.course}
                            error={!!errors.course}
                            helperText={errors.course}
                            disabled={coursesLoading}
                            getOptionLabel={option => option?.name ?? '-'}
                            onChange={handleChangeAutocompleteField<CourseEntity>('course')}
                        />

                        <DatepickerSinglePicker
                            date={values.from}
                            onDateChange={handleChangeDate('from')}
                        >
                            {datepickerProps => {
                                const { inputProps, inputComponent } = datepickerProps;

                                return (
                                    <DatepickerTextField
                                        required
                                        id='from'
                                        label={t('common:forms.labels.start_lap_date')}
                                        placeholder={t('common:forms.labels.date') ?? ''}
                                        error={!!errors.from}
                                        helperText={errors.from}
                                        InputProps={{ inputComponent }}
                                        {...inputProps}
                                    />
                                );
                            }}
                        </DatepickerSinglePicker>

                        <DatepickerSinglePicker
                            date={values.to}
                            onDateChange={handleChangeDate('to')}
                        >
                            {datepickerProps => {
                                const { inputProps, inputComponent } = datepickerProps;

                                return (
                                    <DatepickerTextField
                                        required
                                        id='to'
                                        label={t('common:forms.labels.end_lap_date')}
                                        placeholder={t('common:forms.labels.date') ?? ''}
                                        error={!!errors.to}
                                        helperText={errors.to}
                                        InputProps={{ inputComponent }}
                                        {...inputProps}
                                    />
                                );
                            }}
                        </DatepickerSinglePicker>

                        <StyledFormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name='isFree'
                                        checked={values.isFree}
                                        onChange={handleChange}
                                    />
                                }
                                label={t('lap:form.labels.free')}
                            />
                        </StyledFormGroup>

                        <StyledFormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name='canCertificate'
                                        checked={values.canCertificate}
                                        onChange={handleChange}
                                    />
                                }
                                label={t('lap:form.labels.certificate')}
                            />
                        </StyledFormGroup>

                        <FormRowSubmit>
                            <Button variant='outlined' disabled={isSubmitting} onClick={onCancel}>
                                {t('common:actions.cancel')}
                            </Button>

                            <Button type='submit' loading={isSubmitting}>
                                {t('common:actions.add')}
                            </Button>
                        </FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { LapCreateForm };
