import { useLazyQuery } from '@apollo/client';

import { GetSchoolNumbersQuery } from '@modules/shared/graphql';

import type { QueryLazyOptions, LazyQueryResult, QueryHookOptions } from '@apollo/client';
import type {
    GetSchoolNumbersQueryType,
    GetSchoolNumbersQueryVariables,
} from '@modules/types/graphql';

type Tuple = [
    (options?: QueryLazyOptions<GetSchoolNumbersQueryVariables>) => void,
    LazyQueryResult<GetSchoolNumbersQueryType, GetSchoolNumbersQueryVariables> & {
        schoolNumbers: (number | null)[];
    },
];

const useLazySchoolNumbers = (
    options?: QueryHookOptions<GetSchoolNumbersQueryType, GetSchoolNumbersQueryVariables>,
): Tuple => {
    const [getSchoolNumbers, queryResult] = useLazyQuery<
        GetSchoolNumbersQueryType,
        GetSchoolNumbersQueryVariables
    >(GetSchoolNumbersQuery, options);

    const schoolNumbers = queryResult.data?.getSchoolNumbers ?? [];

    return [getSchoolNumbers, { schoolNumbers, ...queryResult }];
};

export { useLazySchoolNumbers };
