import React from 'react';
import _isEqual from 'lodash/isEqual';

import { useLazySchools } from '@modules/shared/hooks';

import type { SchoolCollectionFilter, SchoolType } from '@modules/types/graphql';

type UseSchoolsFilterOptions = {
    refLocationId?: string;
    refSchoolType?: string;
    filter?: SchoolCollectionFilter;
};

const useSchoolsFilter = (options: UseSchoolsFilterOptions) => {
    const { refLocationId, refSchoolType, filter } = options;

    const filterRef = React.useRef<SchoolCollectionFilter>();

    const [getSchools, { schools, count: schoolsCount, loading: schoolsLoading }] =
        useLazySchools();

    React.useEffect(() => {
        if (!_isEqual(filter, filterRef)) {
            filterRef.current = filter;
        }
    });

    React.useEffect(() => {
        if (!refLocationId) {
            return;
        }

        const schoolType = refSchoolType ? (refSchoolType as SchoolType) : undefined;

        getSchools({
            variables: {
                args: {
                    filter: {
                        type: schoolType,
                        location: { id: refLocationId },
                        ...filterRef.current,
                    },
                    page: { all: true },
                },
            },
        });
    }, [refLocationId, refSchoolType]);

    const result = {
        schools,
        schoolsCount,
        schoolsLoading,
        getSchools,
    };

    return result;
};

export { useSchoolsFilter };
