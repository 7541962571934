import { CollectionRequest } from '@modules/layout/requests';

import type { LapsTableFiltersQuery } from '@modules/lap/hooks';
import type { LapCollectionFilter } from '@modules/types/graphql';

class LapCollectionRequest extends CollectionRequest {
    filter?: LapCollectionFilter;

    constructor(values: LapsTableFiltersQuery['filters']) {
        super(values);

        this.filter = {};

        if (values.courseId) {
            this.filter = {
                ...this.filter,

                courseId: values.courseId,
            };
        }
    }
}

export { LapCollectionRequest };
