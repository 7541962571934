import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import {
    OlCourseTestStatus,
    OlCourseCertificateType,
    OlCourseResultScoreType,
} from '@modules/types/graphql';
import type { OlCourse, OlCourseTest } from '@modules/types/graphql';

export type Entity = Partial<OlCourse> | null;

export type CourseTest = Omit<OlCourseTest, '__typename'>;

@DecribeEntity('CourseEntity')
class CourseEntity extends BaseEntity {
    id: string;
    name: string;
    certificateUrl: string;
    certificateType: OlCourseCertificateType;
    test: CourseTest;
    testAvailable: boolean;
    testStatus: OlCourseTestStatus;
    subjectsCount: number;
    testsCount: number;
    isAvailable: boolean;
    passTestForAnyResult: boolean;
    sort: number;

    constructor(course: Entity) {
        super(course);

        this.id = course?.id ?? '';
        this.name = course?.name ?? '';
        this.certificateUrl = course?.certificateUrl ?? '';
        this.certificateType = course?.certificateType ?? OlCourseCertificateType.default;
        this.testAvailable = course?.testAvailable ?? false;
        this.testStatus = course?.testStatus ?? OlCourseTestStatus.none;
        this.subjectsCount = course?.subjectsCount ?? 0;
        this.testsCount = course?.testsCount ?? 0;
        this.isAvailable = course?.isAvailable ?? false;
        this.passTestForAnyResult = course?.passTestForAnyResult ?? false;
        this.test = course?.test ?? {
            duration: 0,
            questionsCount: 0,
            showResultScore: OlCourseResultScoreType.in_100,
        };
        this.sort = course?.sort ?? 0;
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }

    hasQuestions() {
        const hasQuestions = this.test.questionsCount !== 0;

        return hasQuestions;
    }

    hasTime() {
        const hasTime = this.test.duration !== 0;

        return hasTime;
    }

    isComplete() {
        const result = this.testStatus === OlCourseTestStatus.success;

        return result;
    }
}

export { CourseEntity };
