import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { red } from '@avangard/ui/colors';
import { Autocomplete, Button } from '@avangard/ui/core';
import { Star } from '@material-ui/icons';

import { FormStyled } from '@modules/layout/styled';
import { Form } from '@modules/layout/organisms';
import { useAccessOLCourses } from '@modules/courses/hooks';
import { useCurrentLap, useLazyAccessLaps } from '@modules/lap/hooks';

import type { LapEntity } from '@modules/lap/entities';
import type { CourseEntity } from '@modules/courses/entities';

type ChangeLapFormProps = {
    onCancel: () => void;
};

const StyledForm = styled(Form)`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;

    > *:not(:last-child) {
        margin-bottom: 24px;
    }
`;

const FormRowSubmit = styled(FormStyled.FormRowSubmit)`
    justify-content: flex-end;
`;

const LapOption = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const starIcon = <Star style={{ fontSize: 16, color: red[100] }} />;

const ChangeLapForm = (props: ChangeLapFormProps): React.ReactElement => {
    const { onCancel } = props;

    const { t } = useTranslation(['common', 'lap']);

    const { values, errors, setFieldValue, isSubmitting } = useFormikContext<{
        course: CourseEntity;
        lap: LapEntity;
    }>();

    const canChooseLap = !!values.course;

    const { currentLap, loading: currentLapLoading } = useCurrentLap();

    const { courses, loading: coursesLoading } = useAccessOLCourses({
        fetchPolicy: 'network-only',
    });

    const [getAccessLaps, { laps, loading: lapsLoading }] = useLazyAccessLaps({
        fetchPolicy: 'network-only',
    });

    const handleChangeAutocomplete =
        <T extends any>(field: string) =>
        (_: React.ChangeEvent<{}>, value: T | null) => {
            setFieldValue(field, value);

            if (field === 'course') {
                setFieldValue('lap', null);
            }
        };

    React.useEffect(() => {
        if (!values.course) {
            return;
        }

        getAccessLaps({
            variables: {
                args: {
                    filter: {
                        courseId: values.course.id,
                    },
                    page: { all: true },
                },
            },
        });
    }, [values.course]);

    return (
        <StyledForm>
            <Autocomplete<CourseEntity | null, false, false, false>
                fullWidth
                required
                id='course'
                label={t('common:forms.labels.course') ?? ''}
                placeholder={t('common:forms.placeholder.choose_course') ?? ''}
                options={courses}
                value={values.course}
                error={!!errors.course}
                helperText={errors.course}
                disabled={coursesLoading}
                getOptionLabel={option => option?.name ?? '-'}
                onChange={handleChangeAutocomplete<CourseEntity>('course')}
            />

            <Autocomplete<LapEntity | null, false, false, false>
                fullWidth
                required
                id='lap'
                label={t('common:forms.labels.lap') ?? ''}
                placeholder={t('common:forms.placeholder.choose_lap') ?? ''}
                options={laps}
                value={values.lap}
                error={!!errors.lap}
                helperText={errors.lap}
                disabled={currentLapLoading || lapsLoading || !canChooseLap}
                getOptionLabel={option => option?.getFullDate() ?? '-'}
                renderOption={option => (
                    <LapOption>
                        {option?.getFullDate() ?? '-'}
                        {option?.isActiveLap(currentLap) ? starIcon : null}
                    </LapOption>
                )}
                onChange={handleChangeAutocomplete<LapEntity>('lap')}
            />

            <FormRowSubmit>
                <Button variant='outlined' disabled={isSubmitting} onClick={onCancel}>
                    {t('common:actions.cancel')}
                </Button>

                <Button type='submit' loading={isSubmitting}>
                    {t('common:actions.submit')}
                </Button>
            </FormRowSubmit>
        </StyledForm>
    );
};

export { ChangeLapForm };
