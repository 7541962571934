import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { Button } from '@avangard/ui/core';
import { red } from '@avangard/ui/colors';

import { ListFilters } from '@modules/layout/moleculas';
import { usePageFilters } from './page-filters-context';
import { usePageFiltersDrawer } from './page-filters-drawer-context';

import type { Theme } from '@material-ui/core';

type PageFiltersToolbarProps = {
    hideDrawerFilterButton?: boolean;
    className?: string;
    children?: React.ReactNode;
    afterButtonChildren?: React.ReactNode;
};

const useButtonFilterStyles = makeStyles<Theme, { active?: boolean }>({
    root: p => {
        let properties: Record<string, any> = {
            '&&': {
                minWidth: 135,
            },
        };

        if (p.active) {
            properties = {
                ...properties,
                color: red[100],
                borderColor: red[100],
            };
        }

        return properties;
    },
});

const filterIcon = <FilterList style={{ fontSize: 16 }} />;

const PageFiltersToolbar = (props: PageFiltersToolbarProps) => {
    const { className, hideDrawerFilterButton, children, afterButtonChildren } = props;

    const { t } = useTranslation(['common']);

    const { onOpen } = usePageFiltersDrawer();
    const { drawerFiltersCount } = usePageFilters();

    const hasDrawerFilters = drawerFiltersCount !== 0;
    const buttonClasses = useButtonFilterStyles({ active: hasDrawerFilters });

    const displayFilterTitle = !hasDrawerFilters
        ? t('common:blocks.filters.title')
        : t('common:blocks.filters.title_with_count', { count: drawerFiltersCount });

    return (
        <ListFilters className={className}>
            {children}

            {hideDrawerFilterButton ? null : (
                <Button
                    variant='outlined'
                    classes={buttonClasses}
                    startIcon={filterIcon}
                    onClick={onOpen}
                >
                    {displayFilterTitle}
                </Button>
            )}

            {afterButtonChildren}
        </ListFilters>
    );
};

export { PageFiltersToolbar };
