import { gql } from '@apollo/client';

import { LapAccessFragment } from '../fragments';

export const UpdateLapAccessMutation = gql`
    mutation UpdateLapAccess($id: ID!, $item: LapAccessUpdateArgs!) {
        updateLapAccess(id: $id, item: $item) {
            ...LapAccess
        }
    }
    ${LapAccessFragment}
`;
