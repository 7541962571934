import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { JivositeEntity } from '@modules/auth/entities';
import { GetJivositeInfo } from '@modules/auth/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetJivositeInfoQueryType } from '@modules/types/graphql';

const useJivositeInfo = (options?: QueryHookOptions<GetJivositeInfoQueryType>) => {
    const queryResult = useQuery<GetJivositeInfoQueryType>(GetJivositeInfo, options);

    const jivoInfo = useEntity(
        () => new JivositeEntity(queryResult.data?.getJivositeInfo ?? null),
        [queryResult.data],
    );

    const result = {
        jivoInfo,
        ...queryResult,
    };

    return result;
};

export { useJivositeInfo };
