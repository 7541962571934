import React from 'react';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { useNavigate } from '@lib/routing';
import { createLocationSearchFromState } from '@modules/shared/helpers';
import { MobileHeaderLogo } from '@modules/layout/icons';
import { HeaderButtonBack } from '@modules/layout/atoms';
import { DialogSureBack } from '@modules/layout/moleculas';
import { useBaseLayout } from '@modules/layout/templates';

import type { Route } from '@config/routes';

type BackProps = Pick<Route, 'withBackIcon'> & {
    withBackIcon?: boolean;
};

const Logo = styled(MobileHeaderLogo)`
    flex: 0 0 auto;
    height: 24px;
    width: 24px;
    margin-inline-end: 12px;
`;

const Back = (props: BackProps): React.ReactElement | null => {
    const { withBackIcon } = props;

    const location = useLocation();
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    const { sureBackProps, onRedirect } = useBaseLayout();

    return React.useMemo(() => {
        const backLinkState = { ...(location.state as Record<string, any>) };
        const backPath = backLinkState?.backPath;

        if (backPath) delete backLinkState.backPath;

        const backLink = createLocationSearchFromState({ state: backLinkState });

        const handleClickRedirectBack = (): void => {
            navigate(backLink, { state: backLinkState });
        };

        if (sureBackProps) {
            return (
                <DialogSureBack
                    onCancel={onRedirect ?? handleClickRedirectBack}
                    {...sureBackProps}
                />
            );
        }

        if (!withBackIcon && isMobile) {
            return <Logo />;
        }

        if (!withBackIcon) {
            return null;
        }

        return <HeaderButtonBack onClick={onRedirect ?? handleClickRedirectBack} />;
    }, [location, sureBackProps, withBackIcon, isMobile, onRedirect]);
};

export { Back };
