import { gql } from '@apollo/client';

export const GetSchoolSummaryStatsQuery = gql`
    query GetSchoolSummaryStats($schoolId: ID!, $lapId: ID) {
        getSchoolSummaryStats(schoolId: $schoolId, lapId: $lapId) {
            total
            completed
            out
            averageScore
        }
    }
`;
