import React from 'react';
import styled from 'styled-components';
import { black, gray, white } from '@avangard/ui/colors';
import { breakpoints } from '@avangard/ui/utils';

import { useExternalLayout } from '@modules/layout/templates';
import { ExternalPageLogo } from '@modules/layout/icons';
import { useCurrentUser } from '@modules/user/hooks';

const headerHeight = '64px';

const Root = styled.div`
    height: ${headerHeight};
    background: ${white[100]};
    width: 100%;
    flex: 0 0 auto;
    top: 0;
    left: 0;
    position: sticky;
    z-index: 1;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${gray[40]};
`;

const Logo = styled.div`
    width: 230px;
    height: 100%;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    ${breakpoints.down('sm')} {
        width: 200px;
    }

    ${breakpoints.down('xs')} {
        margin-left: auto;
        margin-right: auto;
    }
`;

const Title = styled.div`
    color: ${black[100]};
    font-size: 2rem;
    font-weight: 700;
    position: absolute;
    height: 100%;
    width: calc(100% - 230px - 320px);
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    ${breakpoints.down('sm')} {
        flex: 1 1;
        position: static;
        margin-right: 0;
        margin-left: auto;
        padding-right: 24px;
        width: calc(100% - 230px);
        justify-content: flex-end;
    }

    ${breakpoints.down('xs')} {
        display: none;
    }
`;

const User = styled.div`
    font-size: 1.4rem;
    margin-left: auto;
    margin-right: 24px;

    ${breakpoints.down('sm')} {
        display: none;
    }
`;

const HeaderExternalLayout = (): React.ReactElement => {
    const { headerTitle } = useExternalLayout();

    const { currentUser } = useCurrentUser();

    return (
        <Root>
            <Logo>
                <ExternalPageLogo />
            </Logo>
            <Title>
                <span>{headerTitle}</span>
            </Title>
            <User>
                {currentUser.isAnonymously() ? `ID: ${currentUser.id}` : currentUser.fullName}
            </User>
        </Root>
    );
};

export { HeaderExternalLayout };
