export { SchoolSingleFragment, SchoolCollectionFragment } from './fragments';
export {
    GetSchoolCollectionQuery,
    GetSchoolCollectionLazyQuery,
    GetSchoolQuery,
    GetSchoolSummaryStatsQuery,
} from './queries';
export {
    CreateSchoolMutation,
    UpdateSchoolMutation,
    RemoveSchoolMutation,
    SendSchoolAccessMutation,
} from './mutations';
