import { gql } from '@apollo/client';

import { CourseCollectionFragment } from '../fragments';

export const CreateCourseMutation = gql`
    mutation CreateCourse($item: OLCourseArgs!) {
        createOLCourse(item: $item) {
            ...CourseCollection
        }
    }
    ${CourseCollectionFragment}
`;
