import { createCustomError } from '@lib/errors';
import { i18n } from '@lib/i18n';

import type { LapEntity, LapAccessEntity } from '@modules/lap/entities';
import type { CourseEntity } from '@modules/courses/entities';
import type { Platform, LapAccessCreateArgs, LapAccessTarget } from '@modules/types/graphql';

export type CreateLapAccessFormValues = {
    neededCopyFromLap?: boolean;
    platform?: Platform | '';
    course?: CourseEntity | null;
    lap: LapEntity | null;
    fromLap?: LapAccessEntity | null;
};

export type CreateLapAccessExtra = {
    target?: LapAccessTarget;
    targetId?: string;
};

class CreateLapAccessRequest {
    target: LapAccessCreateArgs['target'];
    targetId: LapAccessCreateArgs['targetId'];
    lapId: LapAccessCreateArgs['lapId'];
    fromLapId: LapAccessCreateArgs['fromLapId'];

    constructor(values: CreateLapAccessFormValues, extra: CreateLapAccessExtra) {
        if (!values.lap || !extra.target || !extra.targetId) {
            throw createCustomError(i18n.t('errors:lap.invalid'));
        }

        this.target = extra.target;
        this.targetId = extra.targetId;
        this.lapId = values.lap.id;
        this.fromLapId = values.fromLap?.lap?.id;
    }
}

export { CreateLapAccessRequest };
