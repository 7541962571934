import { useTranslation } from 'react-i18next';
import { Modal } from '@avangard/ui/core';

import { LapAccessCreateForm } from '@modules/lap/organisms';

import type { ModalProps } from '@avangard/ui/core';
import type { LapAccessCreateFormProps } from '@modules/lap/organisms';

type LapAccessCreateFormModalProps = Omit<ModalProps, 'title' | 'children'> &
    Omit<LapAccessCreateFormProps, 'onCancel'>;

const LapAccessCreateFormModal = (props: LapAccessCreateFormModalProps) => {
    const { target, targetId, onCreateLapAccess, onClose, children, ...otherProps } = props;

    const { t } = useTranslation(['access']);

    return (
        <Modal
            title={t('access:actions.add_single_access') ?? ''}
            onClose={onClose}
            {...otherProps}
        >
            <LapAccessCreateForm
                target={target}
                targetId={targetId}
                onCancel={onClose}
                onCreateLapAccess={onCreateLapAccess}
            >
                {children}
            </LapAccessCreateForm>
        </Modal>
    );
};

export { LapAccessCreateFormModal };
