const clearJivoWidget = (jivoId: string) => {
    const entities = [`#jivo-widget-${jivoId}`, '#jivo-iframe-container', 'jdiv'];

    entities.forEach(entity => {
        const node = document.querySelector(entity);

        if (node) {
            node.remove();
        }
    });

    localStorage.removeItem('jivoInfo');

    delete window.jivo_magic_var;
};

export { clearJivoWidget };
