import { gql } from '@apollo/client';

import { SchoolSingleFragment } from '../fragments';

export const UpdateSchoolMutation = gql`
    mutation UpdateSchool($id: ID!, $item: SchoolArgs!) {
        updateSchool(id: $id, item: $item) {
            ...SchoolSingle
        }
    }
    ${SchoolSingleFragment}
`;
