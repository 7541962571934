export enum EljurUserRole {
    student = 'student',
    teacher = 'teacher',
    parent = 'parent',
    administrator = 'administrator',
}

export const eljurRoleTranslates = {
    [EljurUserRole.student]: 'common:blocks.eljur_user_roles.student',
    [EljurUserRole.teacher]: 'common:blocks.eljur_user_roles.teacher',
    [EljurUserRole.parent]: 'common:blocks.eljur_user_roles.parent',
    [EljurUserRole.administrator]: 'common:blocks.eljur_user_roles.administrator',
};

export const eljurRoleOptions = Object.entries(eljurRoleTranslates).map(([role, value]) => ({
    key: role,
    value,
}));
