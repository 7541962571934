import './jivo-onload-callback';

const createJivoWidget = (jivoId: string) => {
    const script = document.createElement('script');

    script.id = `jivo-widget-${jivoId}`;
    script.type = 'text/javascript';
    script.async = true;
    script.src = `//code.jivosite.com/script/widget/${jivoId}`;

    document.body.appendChild(script);
};

export { createJivoWidget };
