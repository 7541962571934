import React from 'react';
import _noop from 'lodash/noop';

import { useEvent } from '@modules/shared/hooks';

const DEFAULT_VALUE = {
    open: false,
    onOpen: _noop,
    onClose: _noop,
};

type PageFiltersDrawerContextValue = {
    open?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
};

type PageFiltersDrawerProps = PageFiltersDrawerContextValue & {
    children: React.ReactNode;
};

const PageFiltersDrawerContext = React.createContext<PageFiltersDrawerContextValue>(DEFAULT_VALUE);

const PageFiltersDrawerProvider = (props: PageFiltersDrawerProps) => {
    const { children, open, onOpen, onClose } = props;

    const [innerOpen, setInnerOpen] = React.useState(open);

    const handleOpenDrawer = useEvent((): void => {
        if (typeof open !== 'undefined' && onOpen) {
            onOpen();
        } else {
            setInnerOpen(true);
        }
    });

    const handleCloseDrawer = useEvent((): void => {
        if (typeof open !== 'undefined' && onClose) {
            onClose();
        } else {
            setInnerOpen(false);
        }
    });

    React.useEffect(() => setInnerOpen(open), [open]);

    const ctx = React.useMemo(
        () => ({
            open: innerOpen,
            onOpen: handleOpenDrawer,
            onClose: handleCloseDrawer,
        }),
        [innerOpen],
    );

    return (
        <PageFiltersDrawerContext.Provider value={ctx}>
            {children}
        </PageFiltersDrawerContext.Provider>
    );
};

const usePageFiltersDrawer = (): Required<PageFiltersDrawerContextValue> => {
    const context = React.useContext(PageFiltersDrawerContext);

    if (context === undefined) {
        throw new Error('page filters drawer context value are undefined');
    }

    return context as unknown as Required<PageFiltersDrawerContextValue>;
};

export { PageFiltersDrawerProvider, usePageFiltersDrawer };
