import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Region } from '@modules/types/graphql';

export type Entity = Region | null;

@DecribeEntity('RegionEntity')
class RegionEntity extends BaseEntity {
    id: string;
    name: string;

    constructor(location: Entity) {
        super(location);

        this.id = location?.id ?? '';
        this.name = location?.name ?? '-';
    }

    exists() {
        return this.id !== '';
    }
}

export { RegionEntity };
