import { EntityList } from '@lib/entity';
import { StudentProfileSectionEntity } from './student-profile-section.entity';

import type { Entity } from './student-profile-section.entity';

class StudentProfileSectionEntityList extends EntityList<StudentProfileSectionEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(StudentProfileSectionEntity, items, count);
    }
}

export { StudentProfileSectionEntityList };
