import { format } from 'date-fns';
import { i18n } from '@lib/i18n';
import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { OnlineStudentPerformanceSubject } from '@modules/types/graphql';

export type Entity = Partial<OnlineStudentPerformanceSubject> | null;

@DecribeEntity('OnlineStudentPerformanceSubjectEntity')
class OnlineStudentPerformanceSubjectEntity extends BaseEntity {
    id: string;
    subjectId: string;
    subjectName: string;
    scoreInPoints: number;
    score: number;
    resultSuccess: boolean;
    startedAt: string;
    finishedAt: string;

    constructor(entity: Entity) {
        super(entity);

        this.id = entity?.id ?? '';
        this.subjectName = entity?.subjectName ?? '';
        this.subjectId = entity?.subjectId ?? '';
        this.scoreInPoints = entity?.scoreInPoints ?? 0;
        this.score = entity?.score ?? 0;
        this.resultSuccess = entity?.resultSuccess ?? false;
        this.startedAt = entity?.startedAt ?? '';
        this.finishedAt = entity?.finishedAt ?? '';
    }

    getFormattedScoreInPoints() {
        return this.scoreInPoints.toFixed(2);
    }

    getFormattedStartedAt() {
        if (!this.startedAt) {
            return '-';
        }

        try {
            const validDate = new Date(this.startedAt);
            const date = format(validDate, 'dd.MM.yyyy');
            const time = format(validDate, 'HH:mm:ss');

            const formatted = i18n.t('common:blocks.time.date_to_minutes', { date, time });

            return formatted;
        } catch {
            return '-';
        }
    }

    getFormattedFinishedAt() {
        if (!this.finishedAt) {
            return '-';
        }

        try {
            const validDate = new Date(this.finishedAt);
            const date = format(validDate, 'dd.MM.yyyy');
            const time = format(validDate, 'HH:mm:ss');

            const formatted = i18n.t('common:blocks.time.date_to_minutes', { date, time });

            return formatted;
        } catch {
            return '-';
        }
    }
}

export { OnlineStudentPerformanceSubjectEntity };
