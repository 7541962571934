import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';

import { FileInfoEntity } from './file-info-entity';

import type { VideoFileDoc } from '@modules/types/graphql';

export type Entity = Partial<VideoFileDoc> | null;

@DecribeEntity('VideoFileDocumentEntity')
class VideoFileDocumentEntity extends BaseEntity {
    name: string;
    fileId: string;
    previewName: string | null;
    previewFileId: string | null;

    @Relation(() => FileInfoEntity)
    file: FileInfoEntity;

    @Relation(() => FileInfoEntity)
    previewFile: FileInfoEntity;

    constructor(entity: Entity) {
        super(entity);

        this.name = entity?.name ?? '';
        this.fileId = entity?.fileId ?? '';
        this.previewName = entity?.previewName ?? null;
        this.previewFileId = entity?.previewFileId ?? null;
    }
}

export { VideoFileDocumentEntity };
