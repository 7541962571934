import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakpoints } from '@avangard/ui/utils';

import { AccessErrorPlaceholderIcon, AuthErrorPlaceholderIcon } from '@modules/layout/helpers';
import { ButtonToIndexPage } from '@modules/layout/atoms';

export enum ErrorPlaceholderType {
    notAccess = 'notAccess',
    notAuth = 'notAuth',
    notFound = 'notFound',
    notAvailableTest = 'notAvailableTest',
    notLoadedOnlineLearning = 'notLoadedOnlineLearning',
}

export const errorPlaceholderText: Record<ErrorPlaceholderType, string> = {
    [ErrorPlaceholderType.notAccess]: 'errors:layout.have_not_access',
    [ErrorPlaceholderType.notAuth]: 'errors:layout.have_not_auth',
    [ErrorPlaceholderType.notFound]: 'errors:layout.have_not_found',
    [ErrorPlaceholderType.notAvailableTest]: 'errors:layout.not_available_test',
    [ErrorPlaceholderType.notLoadedOnlineLearning]: 'errors:layout.not_loaded_online_learning',
};

const errorPlaceholderImages: Partial<Record<ErrorPlaceholderType, string>> = {
    [ErrorPlaceholderType.notAccess]: AccessErrorPlaceholderIcon,
    [ErrorPlaceholderType.notAuth]: AuthErrorPlaceholderIcon,
    [ErrorPlaceholderType.notFound]: AuthErrorPlaceholderIcon,
    [ErrorPlaceholderType.notAvailableTest]: AccessErrorPlaceholderIcon,
    [ErrorPlaceholderType.notLoadedOnlineLearning]: AuthErrorPlaceholderIcon,
};

const errorPlaceholderButtons: Partial<Record<ErrorPlaceholderType, JSX.Element>> = {
    [ErrorPlaceholderType.notAccess]: <ButtonToIndexPage />,
};

type ErrorPlaceholderProps = {
    errorType: keyof typeof ErrorPlaceholderType;
};

const Root = styled.div`
    flex-grow: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

const Image = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 auto;
    margin-bottom: 32px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    ${breakpoints.down(991)} {
        width: 254px;
    }

    ${breakpoints.down(560)} {
        width: 200px;
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    & > *:not(:last-child) {
        margin-bottom: 30px;
    }
`;

const Text = styled.p`
    font-size: 2.2rem;
    white-space: pre-wrap;

    ${breakpoints.down(991)} {
        font-size: 1.8rem;
    }
`;

const ErrorPlaceholder = (props: ErrorPlaceholderProps): React.ReactElement => {
    const { errorType } = props;

    const { t } = useTranslation(['errors']);

    return (
        <Root>
            <Image>
                <img src={errorPlaceholderImages[errorType]} alt='' />
            </Image>

            <Content>
                <Text dangerouslySetInnerHTML={{ __html: t(errorPlaceholderText[errorType]) }} />
                {errorPlaceholderButtons[errorType]}
            </Content>
        </Root>
    );
};

export { ErrorPlaceholder };
