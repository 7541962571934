import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { Button, TextField } from '@avangard/ui/core';
import { concatenateClasses } from '@avangard/ui/utils';

import type { ButtonProps, TextFieldProps } from '@avangard/ui/core';

type RemovableTextFieldRowProps = TextFieldProps & {
    onRemove: () => void;
    className?: string;
    ButtonProps?: ButtonProps;
};

const useButtonStyles = makeStyles({
    root: {
        minWidth: 40,
        width: 40,
    },
    startIcon: {
        margin: 0,
    },
});

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;

    > *:first-child {
        margin-inline-end: 6px;
    }

    > *:last-child {
        width: calc(100% - 46px);
    }
`;

const deleteIcon = <Delete style={{ fontSize: 20 }} />;

const RemovableTextFieldRow = (props: RemovableTextFieldRowProps): React.ReactElement => {
    const { className, onRemove, ButtonProps, ...otherProps } = props;

    const buttonClasses = concatenateClasses(useButtonStyles(), ButtonProps?.classes);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        if (ButtonProps && ButtonProps.onClick) {
            ButtonProps.onClick(event);
        }

        onRemove();
    };

    return (
        <Root className={className}>
            <Button
                {...ButtonProps}
                startIcon={deleteIcon}
                classes={buttonClasses}
                onClick={handleClick}
            />
            <TextField {...otherProps} />
        </Root>
    );
};

export { RemovableTextFieldRow };
