import { gql } from '@apollo/client';

import { LapAccessFragment } from '../fragments';

export const CreateLapAccessMutation = gql`
    mutation CreateLapAccess($item: LapAccessCreateArgs!) {
        createLapAccess(item: $item) {
            ...LapAccess
        }
    }
    ${LapAccessFragment}
`;
