import { gql } from '@apollo/client';

import { PageReplyFragment } from '@modules/layout/graphql';
import { LapAccessFragment } from '@modules/lap/graphql';

export const GetSchoolCollectionLazyQuery = gql`
    query GetSchoolCollectionLazy($args: SchoolCollectionArgs) {
        getSchoolCollection(args: $args)
            @connection(key: "getSchoolCollectionLazy", filter: ["args"]) {
            items {
                id
                shortName
                lapAccessList {
                    ...LapAccess
                }
            }
            page {
                ...PageReply
            }
        }
    }
    ${LapAccessFragment}
    ${PageReplyFragment}
`;
