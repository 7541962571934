import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { OnlineStudentPerformanceCourse } from '@modules/types/graphql';

export type Entity = Partial<OnlineStudentPerformanceCourse> | null;

@DecribeEntity('OnlineStudentPerformanceCourseEntity')
class OnlineStudentPerformanceCourseEntity extends BaseEntity {
    scoreInPoints: number;
    score: number;
    resultSuccess: boolean;

    constructor(entity: Entity) {
        super(entity);

        this.scoreInPoints = entity?.scoreInPoints ?? 0;
        this.score = entity?.score ?? 0;
        this.resultSuccess = entity?.resultSuccess ?? false;
    }
}

export { OnlineStudentPerformanceCourseEntity };
