import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CourseEntityList } from '@modules/courses/entities';
import { GetCoursesQuery } from '@modules/courses/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetCoursesQueryType, GetCoursesQueryVariables } from '@modules/types/graphql';

const useCourses = (options?: QueryHookOptions<GetCoursesQueryType, GetCoursesQueryVariables>) => {
    const queryResult = useQuery<GetCoursesQueryType, GetCoursesQueryVariables>(
        GetCoursesQuery,
        options,
    );

    const { entities: courses, count } = useEntity(
        () => new CourseEntityList(queryResult.data?.getOLCourses ?? null),
        [queryResult.data],
    );

    const result = {
        courses,
        count,
        ...queryResult,
    };

    return result;
};

export { useCourses };
