import React from 'react';
import { useDebounce } from 'react-use';
import { useLocation } from '@reach/router';

import { CommonHelper } from '@helpers';
import { useLocationQuery } from '@modules/layout/hooks';

type UsePageFiltersPayload<T> = {
    queryFilters: Record<string, any>;
    initials: T;
    drawerFiltersKeys?: string[];
    onCloseDrawer?: () => void;
    onExecDefaultFilterCondition?: (search: string) => boolean;
    disableAutoSetFilters?: boolean;
};

type UsePageFiltersResult<T> = {
    filters: T;
    drawerFiltersCount: number;
    issetFilters: boolean;
    setFilterValue: (field: string, value: any) => void;
    setFilters: (state: T | ((prevState: T) => T)) => void;
    handleSetFilters: (resetted?: T) => void;
    handleChangeField: (event: React.ChangeEvent<{ name: string; value: any }>) => void;
    handleSubmitFilters: () => void;
    handleResetFilters: () => void;
    handleResetDrawerFilters: () => void;
};

const usePageFilters = <T extends Record<string, any>>(
    payload: UsePageFiltersPayload<T>,
): UsePageFiltersResult<T> => {
    const {
        queryFilters,
        initials,
        drawerFiltersKeys = [],
        disableAutoSetFilters,
        onCloseDrawer,
        onExecDefaultFilterCondition,
    } = payload;

    const location = useLocation();
    const [, setQuery] = useLocationQuery();

    const defaultFilters = CommonHelper.fillDefaultValues<T>(queryFilters, initials);

    const [innerValues, setInnerValues] = React.useState<T>(defaultFilters);

    const setFilterValue = (field: string, value: any): void =>
        setInnerValues(state => ({ ...state, [field]: value }));

    const handleChangeField = (event: React.ChangeEvent<{ name: string; value: any }>) => {
        const name = event.target.name;
        const value = event.target.value;

        setFilterValue(name, value);
    };

    const handleSetFilters = (resetted?: T): void => {
        const resultValues = {
            ...innerValues,
            ...(resetted ?? {}),
        };

        const updatedQuery = {
            ...queryFilters,
            ...resultValues,
        };

        if (resetted) {
            setInnerValues(resultValues);
        }

        setQuery(updatedQuery);
    };

    const handleSubmitFilters = (): void => {
        handleSetFilters();
        onCloseDrawer?.();
    };

    const handleResetDrawerFilters = (): void => {
        const resetted = Object.entries(initials).reduce(
            (carry, [key, value]) =>
                drawerFiltersKeys.includes(key) ? { ...carry, [key]: value } : carry,
            {} as T,
        );

        handleSetFilters(resetted);
        onCloseDrawer?.();
    };

    const handleResetFilters = (): void => handleSetFilters(initials);

    const toolbarFiltersDeps = Object.entries(innerValues).map(([key, value]) => {
        if (drawerFiltersKeys.length !== 0) {
            return !drawerFiltersKeys.includes(key) ? value : null;
        }

        return value;
    });

    useDebounce(
        () => {
            if (disableAutoSetFilters) {
                return;
            }

            handleSetFilters();
        },
        200,
        toolbarFiltersDeps,
    );

    React.useEffect(() => {
        let defaultFilterCondition = location.search === '';

        if (onExecDefaultFilterCondition) {
            defaultFilterCondition =
                defaultFilterCondition || onExecDefaultFilterCondition(location.search);
        }

        if (defaultFilterCondition) {
            const filledFilters = CommonHelper.fillDefaultValues<T>(queryFilters, initials);

            setInnerValues(filledFilters);
        }
    }, [location]);

    const drawerFiltersCount = Object.entries(queryFilters)
        .map(([key, value]) => (drawerFiltersKeys.includes(key) ? value : null))
        .filter(filter => filter !== null && typeof filter !== 'undefined').length;

    const issetFilters = Object.values(innerValues).some(filter => !!filter);

    const result = {
        filters: innerValues,
        drawerFiltersCount,
        issetFilters,
        setFilterValue,
        setFilters: setInnerValues,
        handleSetFilters,
        handleChangeField,
        handleSubmitFilters,
        handleResetFilters,
        handleResetDrawerFilters,
    };

    return result;
};

export { usePageFilters };
