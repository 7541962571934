import React from 'react';

import '@modules/init';
import { useInitI18n, TextDirectionProvider } from '@lib/i18n';

import { App } from './app';

const InitApp = (): React.ReactElement | null => {
    const { loading } = useInitI18n();

    if (loading) {
        return null;
    }

    return (
        <TextDirectionProvider>
            <App />
        </TextDirectionProvider>
    );
};

export { InitApp };
