import { i18n } from '@lib/i18n';
import { createRouteUrl } from './create-route-url';

import type { Route } from '@config/routes';

type CreateRoutePayload = Record<string, any> & {
    query?: Record<string, any>;
};

export const createFullUrl = (route: Route | string, payload?: CreateRoutePayload): string => {
    return `${window.location.origin}/${i18n.language}${createRouteUrl(route, payload)}`;
};
