import React from 'react';
import styled from 'styled-components';
import { Menu, Close } from '@material-ui/icons';
import { black, white } from '@avangard/ui/colors';
import { breakpoints } from '@avangard/ui/utils';
import { Hidden, IconButton } from '@material-ui/core';

import { Back } from '@modules/layout/atoms';
import { useBaseLayout } from '@modules/layout/templates';
import { useCurrentUser } from '@modules/user/hooks';
import { HeaderUserInfo } from './header-user-info';

import type { Route } from '@config/routes';

type HeaderProps = Pick<Route, 'withBackIcon'> & {
    openSidebar: boolean;
    onOpenSidebar?: () => void;
    onCloseSidebar?: () => void;
};

const Root = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 64px;
    height: 64px;
    position: relative;
    padding-inline-start: 24px;
    color: ${black[80]};
    background-color: ${white[100]};
    border-bottom: 1px solid ${white[80]};
    z-index: 1000;

    ${breakpoints.down('sm')} {
        position: fixed;
        left: 0;
        top: 0;
        min-height: 56px;
        height: 56px;
        padding: 0 14px;
        color: ${white[100]};
        background-image: url('/images/main-background.jpg');
        background-size: 100% auto;
        border-bottom: none;
    }
`;

const HeaderBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 24px;
`;

const MenuButton = styled(IconButton)`
    color: inherit;
    display: flex;
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 0 0 auto;
`;

const PageTitle = styled.div`
    flex: 1 1 auto;
    width: 100%;
    font-size: 2rem;
    font-weight: 700;
    color: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    ${breakpoints.down('sm')} {
        font-size: 1.8rem;
    }
`;

const HeaderBlockUser = styled(HeaderBlock)`
    flex: 0 0 auto;
    margin-inline-start: auto;
    padding: 0;
`;

const menuIcon = <Menu style={{ color: 'inherit', fontSize: 24 }} />;
const closeIcon = <Close style={{ color: 'inherit', fontSize: 24 }} />;

const Header = (props: HeaderProps): React.ReactElement => {
    const { withBackIcon, openSidebar, onOpenSidebar, onCloseSidebar } = props;

    const { pageTitle } = useBaseLayout();

    const { currentUser } = useCurrentUser();

    return (
        <Root>
            <Back withBackIcon={withBackIcon} />
            <PageTitle title={pageTitle}>{pageTitle}</PageTitle>

            <Hidden smDown>
                {currentUser.authenticated() ? (
                    <HeaderBlockUser>
                        <HeaderUserInfo currentUser={currentUser} />
                    </HeaderBlockUser>
                ) : null}
            </Hidden>

            <Hidden mdUp>
                {openSidebar ? (
                    <MenuButton onClick={onCloseSidebar}>{closeIcon}</MenuButton>
                ) : (
                    <MenuButton onClick={onOpenSidebar}>{menuIcon}</MenuButton>
                )}
            </Hidden>
        </Root>
    );
};

export { Header };
