import './wdyr';
import ReactDOM from 'react-dom';

import { InitApp } from './init-app';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(<InitApp />, document.getElementById('root'));

serviceWorker.unregister();
