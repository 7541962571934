import React from 'react';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { DeleteOutline, Attachment } from '@material-ui/icons';
import { black, gray, red } from '@avangard/ui/colors';
import { breakpoints } from '@avangard/ui/utils';

import { TokenSession } from '@lib/token';
import { createFileLink } from '@lib/routing';
import { REST_URL } from '@config/environment';
import { getShortFilename, isValidFilesize } from '@modules/shared/helpers';
import { UploadHandler } from '@modules/shared/moleculas';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';

import type { UploadHandlerProps } from '@modules/shared/moleculas';

export type UploadedFile = {
    filename?: string | null;
    fileId?: string | null;
    url?: string | null;
};

type FileUploaderProps = Pick<UploadHandlerProps, 'icon' | 'withoutIcon'> & {
    onUploadFile: (file?: UploadedFile) => void;

    accept?: string;
    validationType?: 'image'; // list will be updated in future (back-end validate)
    validationError?: string;
    file?: UploadedFile;
    type?: 'compact' | 'thumbnail';
    label?: string;
    maxSize?: number | null;
    fullWidth?: boolean;
    onDeleteFile?: () => void;
};

const Root = styled.div<{ fullWidth?: boolean; withThumb?: boolean }>`
    display: flex;
    align-items: center;
    position: relative;
    min-height: 32px;

    > input {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
    }

    ${p =>
        !!p.fullWidth &&
        css`
            width: 100%;
        `}

    ${p =>
        !!p.withThumb &&
        css`
            padding: 8px;
            border: 1px solid ${gray[40]};
        `}

    ${breakpoints.down('xs')} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const Detail = styled.div`
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    align-items: center;
    line-height: 20px;

    > * {
        display: inline-flex;
    }
`;

const StyledUploadHandler = styled(UploadHandler)`
    display: inline-flex;
    width: initial;
`;

const Thumbnail = styled.div`
    width: 48px;
    height: 48px;
    margin-inline-end: 8px;
    flex: 0 0 auto;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

const Info = styled.div`
    display: block;
    color: ${red[100]};
    font-size: 1.4rem;
    max-width: 250px;
    flex: 1 1 auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const Icon = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: ${black[40]};
`;

const AttachmentIcon = styled(Icon)`
    margin-inline-end: 4px;
`;

const LoaderWrap = styled.div`
    margin-inline-start: 12px;
`;

const DeleteIcon = styled(Icon)`
    margin-inline-start: auto;
    transition: color 0.2s;
    cursor: pointer;

    &:hover {
        color: ${red[100]};
    }
`;

const Progress = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${black[80]};
    margin-inline-start: 10px;
`;

const HelperText = styled.div<{ error?: boolean }>`
    display: inline-flex;
    margin-inline-start: 10px;
    font-size: 1.4rem;

    ${p =>
        !!p.error &&
        css`
            color: ${red[100]};
        `}

    ${breakpoints.down('xs')} {
        margin-top: 6px;
    }
`;

const attachmentIcon = <Attachment style={{ fontSize: 16 }} />;
const deleteIcon = <DeleteOutline style={{ fontSize: 16 }} />;

const FileUploader = (props: FileUploaderProps): React.ReactElement => {
    const {
        accept,
        icon,
        withoutIcon,
        validationType,
        validationError,
        type = 'compact',
        label,
        file,
        maxSize,
        fullWidth,
        onUploadFile,
        onDeleteFile,
    } = props;

    const { t } = useTranslation(['common']);

    const { enqueueError } = useEnqueueStacks();

    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const [currentFile, setCurrentFile] = React.useState<UploadedFile | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    const [progress, setProgress] = React.useState<number>(0);

    React.useMemo(() => {
        if (file?.filename && file?.fileId) {
            setCurrentFile(file);
        }
    }, [file]);

    const handleClickFile = (): void => {
        if (inputRef?.current?.click) {
            inputRef.current.click();
        }
    };

    const handleDeleteFile = (): void => {
        setCurrentFile(null);
        onUploadFile(undefined);
        onDeleteFile?.();

        if (inputRef?.current) {
            inputRef.current.value = '';
        }
    };

    // TODO: move to another directory, something like "api"
    const startUploadFile = React.useCallback(
        (file: File) => {
            const accessToken = TokenSession.getCurrentSession().getAccessToken().getToken();

            setLoading(true);

            const formData = new FormData();
            const query = validationType ? `?type=${validationType}` : '';

            formData.append('file', file);

            axios
                .post(`${REST_URL}/files${query}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        authorization: `Bearer ${accessToken}`,
                    },
                    onUploadProgress: (progressEvent: ProgressEvent) => {
                        let length = 0;
                        let currentProgress = 0;

                        if (progressEvent.lengthComputable) {
                            length = progressEvent.total;
                        } else {
                            const eventTarget = progressEvent.target as
                                | Record<string, any>
                                | undefined;

                            if (eventTarget && 'getResponseHeader' in eventTarget) {
                                length =
                                    eventTarget.getResponseHeader('content-length') ||
                                    eventTarget.getResponseHeader('x-decompressed-content-length');
                            }
                        }

                        if (length !== null) {
                            currentProgress = Math.round((progressEvent.loaded * 100) / length);
                        }

                        setProgress(currentProgress);
                    },
                })
                .then(response => {
                    const { data } = response;

                    const shortFileName = getShortFilename(file.name);
                    const fileId = data?.response?.file_id;

                    const fileData = {
                        filename: shortFileName,
                        fileId: String(fileId),
                    };

                    onUploadFile(fileId ? fileData : undefined);

                    setProgress(0);
                    setLoading(false);
                })
                .catch(() => {
                    setProgress(0);
                    setLoading(false);
                    handleDeleteFile();

                    if (validationType && validationError) {
                        enqueueError(validationError);
                    }
                });
        },
        [onUploadFile],
    );

    const handlerFileUpload = (_: React.ChangeEvent<{}>): void => {
        if (!inputRef?.current?.files) {
            return;
        }

        const [file] = inputRef.current.files;

        if (!file) {
            return;
        }

        if (maxSize && !isValidFilesize(file, maxSize)) {
            inputRef.current.value = '';

            setError(true);

            setTimeout(() => setError(false), 3000);

            return;
        }

        if (FileReader) {
            const reader = new FileReader();

            reader.onload = () => {
                const shortFileName = getShortFilename(file.name);

                startUploadFile(file);

                setCurrentFile({
                    filename: shortFileName,
                    url: reader.result ? String(reader.result) : undefined,
                });
            };

            reader.readAsDataURL(file);
        }
    };

    const withThumb = type === 'thumbnail' && currentFile !== null;
    const hasProgress = progress > 0;

    return (
        <Root fullWidth={fullWidth} withThumb={withThumb}>
            <input
                type='file'
                name='file'
                accept={accept}
                ref={inputRef}
                onChange={handlerFileUpload}
            />

            {!currentFile ? (
                <StyledUploadHandler
                    title={label ? label : t('common:blocks.file.upload')}
                    icon={icon}
                    withoutIcon={withoutIcon}
                    onClick={handleClickFile}
                />
            ) : (
                <Detail>
                    {withThumb ? (
                        <Thumbnail>
                            <img
                                alt='thumbnail'
                                src={
                                    currentFile.url ?? createFileLink({ path: currentFile.fileId })
                                }
                            />
                        </Thumbnail>
                    ) : (
                        <AttachmentIcon>{attachmentIcon}</AttachmentIcon>
                    )}

                    <Info>{currentFile.filename}</Info>

                    {loading ? (
                        <LoaderWrap>
                            <Loader size={16} />
                        </LoaderWrap>
                    ) : (
                        <DeleteIcon onClick={handleDeleteFile}>{deleteIcon}</DeleteIcon>
                    )}

                    {hasProgress ? <Progress>{progress}%</Progress> : null}
                </Detail>
            )}

            {!!maxSize && !currentFile ? (
                <HelperText error={error}>
                    {t('common:blocks.file.max_size', { maxSize })}
                </HelperText>
            ) : null}
        </Root>
    );
};

export { FileUploader };
