import { gql } from '@apollo/client';

import { LapFragment } from '../fragments';

export const GetAccessLapsQuery = gql`
    query GetAccessLaps($args: LapCollectionArgs) {
        getAccessLaps(args: $args) {
            ...Lap
        }
    }
    ${LapFragment}
`;
