import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@avangard/ui/core';

import { AUTH_DOMAIN } from '@config/environment';

export const ButtonToIndexPage = (): JSX.Element => {
    const { t } = useTranslation(['errors']);

    const handleClickChangePlatform = (): void => {
        window.location.href = AUTH_DOMAIN;
    };

    return <Button onClick={handleClickChangePlatform}>{t('errors:layout.to_index')}</Button>;
};
