import { EntityList } from '@lib/entity';
import { StudentProfileItemEntity } from './student-profile-item.entity';

import type { Entity } from './student-profile-item.entity';

class StudentProfileItemEntityList extends EntityList<StudentProfileItemEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(StudentProfileItemEntity, items, count);
    }
}

export { StudentProfileItemEntityList };
