import { gql } from '@apollo/client';

import { FileInfoFragment } from './file-info';

export const VideoFileDocFragment = gql`
    fragment VideoFileDoc on VideoFileDoc {
        name
        fileId
        file {
            ...FileInfo
        }

        previewName
        previewFileId
        previewFile {
            ...FileInfo
        }
    }
    ${FileInfoFragment}
`;
