import React from 'react';
import ImageEnlarger from 'react-image-enlarger';
import styled, { createGlobalStyle, css } from 'styled-components';
import { ZoomIn } from '@material-ui/icons';
import { black, white } from '@avangard/ui/colors';

type ZoomImageProps = {
    src: string;
    hasMagnifierIcon?: boolean;
    style?: Record<string, any>;
    className?: string;
};

const GlobalImageStyles = createGlobalStyle`
    .EnlargedImage__enlarged-container {
        z-index: 1001!important;
    }
    .EnlargedImage__clone {
        padding: 12px;
    }
`;

const Image = styled.div`
    position: relative;
`;

const Magnifier = styled.div<{ zoomed?: boolean }>`
    cursor: zoom-in;
    position: absolute;
    z-index: 1002;
    border-radius: 50%;
    right: -10px;
    top: -10px;
    background: ${white[100]};
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transition: 0.4s ease-in-out;

    ${p =>
        !!p.zoomed &&
        css`
            transition: 0.3ms ease-in-out;
            opacity: 0;
            pointer-events: none;
        `}
`;

const zoomIcon = <ZoomIn style={{ fontSize: 24, color: black[100] }} />;

const ZoomImage = (props: ZoomImageProps): React.ReactElement => {
    const { hasMagnifierIcon, ...otherProps } = props;

    const [zoomed, setZoomed] = React.useState(false);

    const firstOpen = React.useRef(false);

    const handleClickOpen = (): void => {
        // workaround for bug with "react-image-enlarger"
        // at first open library create clone of image and can not get in time
        // open large image. we emulate double usage of zoomed image at first interactive
        if (!firstOpen.current) {
            firstOpen.current = true;

            setZoomed(true);

            setTimeout(() => setZoomed(false), 0);
            setTimeout(() => setZoomed(true), 60);

            return;
        }

        setZoomed(true);
    };

    const handleClickClose = (): void => setZoomed(false);

    return (
        <>
            <GlobalImageStyles />

            <Image>
                {hasMagnifierIcon && (
                    <Magnifier zoomed={zoomed} onClick={handleClickOpen}>
                        {zoomIcon}
                    </Magnifier>
                )}
                <ImageEnlarger
                    zoomed={zoomed}
                    onClick={handleClickOpen}
                    onRequestClose={handleClickClose}
                    {...otherProps}
                />
            </Image>
        </>
    );
};

export { ZoomImage };
