import { UserRole } from '@modules/types/graphql';

import type { Classifiers } from './typings';

const permissions: Record<UserRole, Partial<Classifiers>> = {
    [UserRole.superAdmin]: {
        laps: ['all'],
        dashboard: ['all'],
        students: ['all', '!show-toolbar-hint'],
        employees: ['all'],
        schools: ['all'],
        stats: ['all'],
        olMain: ['all'],
        olSubjects: ['all'],
        olLectures: ['all'],
        olVideoMaterials: ['all'],
        olTesting: ['all'],
        live: ['all'],
    },
    [UserRole.admin]: {
        laps: ['all'],
        dashboard: ['all'],
        students: ['all', '!show-toolbar-hint'],
        employees: ['all'],
        schools: ['all'],
        stats: ['all'],
        olMain: ['all'],
        olSubjects: ['all'],
        olLectures: ['all'],
        olVideoMaterials: ['all'],
        olTesting: ['all'],
        live: ['all'],
    },
    [UserRole.student]: {
        laps: ['change'],
        olTesting: ['view', 'process'],
    },
    [UserRole.school]: {
        laps: ['change'],
        students: [
            'list',
            'view',
            'search',
            'filter',
            'show-toolbar-hint',
            'create',
            'edit',
            'delete',
            'view-access',
            'send-access',
        ],
        stats: ['list', 'search', 'filter'],
    },
    [UserRole.registrar]: {
        laps: ['all'],
        dashboard: ['all'],
        students: [
            'list',
            'view',
            'search',
            'filter',
            'create',
            'edit',
            'delete',
            'view-access',
            'edit-access',
            'send-access',
        ],
        stats: ['all'],
        employees: ['list', 'search', 'filter'],
        schools: [
            'list',
            'view',
            'filter',
            'search',
            'create',
            'edit',
            'delete',
            'edit-access',
            'send-access',
        ],
        olMain: ['list'],
        olSubjects: ['list', 'view'],
    },
    [UserRole.methodist]: {
        laps: ['list', 'filter', 'create', 'edit', 'delete', 'change'],
        dashboard: ['all'],
        students: ['list', 'search', 'filter'],
        employees: [
            'list',
            'view',
            'search',
            'filter',
            'create',
            'edit',
            'restore',
            'delete',
            'send-access',
        ],
        schools: ['list', 'search', 'filter'],
        stats: ['list', 'search', 'filter', 'download-report'],
        olMain: ['list', 'create', 'edit', 'delete'],
        olSubjects: ['list', 'create', 'edit', 'delete'],
        olLectures: ['list', 'create', 'edit', 'delete'],
        olVideoMaterials: ['list', 'create', 'edit', 'delete'],
        olTesting: ['view', 'edit'],
        live: ['edit', 'start', 'stop'],
    },
    [UserRole.instructor]: {
        laps: ['change'],
        dashboard: ['all'],
        students: ['list', 'search', 'filter'],
        employees: ['list', 'search', 'filter'],
        schools: ['list', 'filter', 'search'],
        stats: ['list', 'search', 'filter'],
        olMain: ['list'],
        olSubjects: ['list'],
        olLectures: ['list'],
        live: ['edit', 'start', 'stop'],
    },
    [UserRole.mentor]: {
        laps: ['change'],
        dashboard: ['all'],
        students: [
            'list',
            'view',
            'search',
            'filter',
            'show-toolbar-hint',
            'edit',
            'view-access',
            'send-access',
        ],
        employees: ['list', 'search', 'filter'],
        schools: ['list', 'filter', 'search'],
        stats: ['list', 'search', 'filter'],
        olMain: ['list'],
        olSubjects: ['list'],
        olLectures: ['list'],
    },
    [UserRole.leader]: {
        laps: ['change'],
        dashboard: ['all'],
        students: ['list', 'search', 'filter', 'edit'],
        employees: [
            'list',
            'view',
            'search',
            'filter',
            'create',
            'edit',
            'delete',
            'restore',
            'send-access',
        ],
        schools: ['list', 'search', 'filter'],
        stats: ['list', 'search', 'filter'],
        olMain: ['list'],
        olSubjects: ['list'],
        olLectures: ['list'],
    },
    [UserRole.juniorLeader]: {
        laps: ['change'],
        dashboard: ['all'],
        students: ['list', 'search', 'filter'],
        employees: ['list', 'search', 'filter'],
        schools: ['list', 'search', 'filter'],
        stats: ['list', 'search', 'filter'],
        olMain: ['list'],
        olSubjects: ['list'],
        olLectures: ['list'],
    },
    [UserRole.seniorInstructor]: {
        laps: ['change'],
        dashboard: ['all'],
        students: ['list', 'search', 'filter'],
        employees: [
            'list',
            'view',
            'search',
            'filter',
            'create',
            'edit',
            'delete',
            'restore',
            'send-access',
        ],
        schools: ['list', 'search', 'filter'],
        stats: ['list', 'search', 'filter'],
        olMain: ['list'],
        olSubjects: ['list'],
        olLectures: ['list'],
    },
    [UserRole.psychologist]: {},
    [UserRole.organizingTeacher]: {},
};

const checkPermission = (role: UserRole | null, classifiersKey: string, classifier: string) => {
    if (!role) {
        return false;
    }

    const permissionsClassifiers: string[] = permissions[role][classifiersKey];

    if (permissionsClassifiers) {
        let hasPermission = permissionsClassifiers.includes(classifier);

        if (permissionsClassifiers.includes('all')) {
            const invertPermission = permissionsClassifiers.find(
                permissionsClassifier =>
                    permissionsClassifier.startsWith('!') &&
                    permissionsClassifier === '!' + classifier,
            );

            hasPermission = !invertPermission;
        }

        return hasPermission;
    }

    return false;
};

export { checkPermission };
