import { gql } from '@apollo/client';

export const GetLapsWithCertificatesQuery = gql`
    query GetLapsWithCertificates($schoolId: ID) {
        getLapsWithCertificates(schoolId: $schoolId) {
            certificatesUrl

            lap {
                id
                name
                from
                to

                course {
                    id
                    name
                }
            }
        }
    }
`;
