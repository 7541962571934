import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { LapEntityList } from '@modules/lap/entities';
import { GetLapCollectionQuery } from '@modules/lap/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetLapCollectionQueryType,
    GetLapCollectionQueryVariables,
} from '@modules/types/graphql';

const useLapCollection = (
    options?: QueryHookOptions<GetLapCollectionQueryType, GetLapCollectionQueryVariables>,
) => {
    const queryResult = useQuery<GetLapCollectionQueryType, GetLapCollectionQueryVariables>(
        GetLapCollectionQuery,
        options,
    );

    const { entities: laps, count } = useEntity(
        () =>
            new LapEntityList(
                queryResult.data?.getLapCollection?.items ?? null,
                queryResult.data?.getLapCollection?.page?.total ?? null,
            ),
        [queryResult.data],
    );

    const result = {
        laps,
        count,
        ...queryResult,
    };

    return result;
};

export { useLapCollection };
