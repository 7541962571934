import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';
import { SchoolSummaryStats } from '@modules/types/graphql';

export type Entity = Partial<SchoolSummaryStats> | null;

@DecribeEntity('SchoolSummaryStatsEntity')
class SchoolSummaryStatsEntity extends BaseEntity {
    total: number;
    completed: number;
    out: number;
    averageScore: number;

    constructor(schoolSummaryStats: Entity) {
        super(schoolSummaryStats);

        this.total = schoolSummaryStats?.total ?? 0;
        this.completed = schoolSummaryStats?.completed ?? 0;
        this.out = schoolSummaryStats?.out ?? 0;
        this.averageScore = schoolSummaryStats?.averageScore ?? 0;
    }
}

export { SchoolSummaryStatsEntity };
