import { gql } from '@apollo/client';

export const CourseCollectionFragment = gql`
    fragment CourseCollection on OLCourse {
        id
        name
        testsCount
        testAvailable
        testStatus
        subjectsCount
        isAvailable
        certificateUrl
        sort
    }
`;
