export const getParentNode = (element: Element, targetClass: string): Element | null => {
    if (!element) {
        return null;
    }

    if (element.classList.contains(targetClass)) {
        return element;
    }

    return getParentNode(element.parentNode as Element, targetClass);
};

export const getAutocompleteIdByElement = (element: Element): string => {
    const parent = getParentNode(element, 'MuiAutocomplete-inputRoot');

    if (!parent || parent.children.length === 0) {
        return '';
    }

    for (const [, childElement] of parent.childNodes.entries()) {
        if (childElement.nodeName.toLowerCase() === 'input') {
            return (childElement as Element).id;
        }
    }

    return parent.children[0].id;
};
