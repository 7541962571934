import qs from 'query-string';
import { useLocation } from '@reach/router';

import { useNavigate } from '@lib/routing';

import type { WindowLocation } from '@reach/router';
import type { ParsedQuery } from 'query-string';

export type LocationQuery = [ParsedQuery<string>, (query?: Record<string, any>) => void];

const getLocationState = (location: WindowLocation<unknown>) =>
    (location.state ?? {}) as Record<string, any>;

const useLocationQuery = (): LocationQuery => {
    const location = useLocation();
    const navigate = useNavigate();

    const initialQuery = qs.parse(location.search);

    const setQuery = (query?: Record<string, any>) => {
        if (!query) {
            navigate(location.pathname);
            return;
        }

        let queryToUpdate = { ...query };

        for (const key in queryToUpdate) {
            if (typeof queryToUpdate[key] === 'object' && !Array.isArray(queryToUpdate[key])) {
                queryToUpdate[key] = JSON.stringify(queryToUpdate[key]);
            }
        }

        const result = qs.stringify(queryToUpdate, { skipEmptyString: true });

        const queryString = result
            ? `${location.pathname}?${result}${location.hash}`
            : location.pathname + location.hash;

        navigate(queryString, { state: getLocationState(location) });
    };

    return [initialQuery, setQuery];
};

export { useLocationQuery };
