import { isValid } from 'date-fns';

type createCountFormatterOptions = {
    withNumber?: boolean;
    decimal?: number;
};

class CommonHelper {
    static fillDefaultValues<T extends Record<string, any>>(
        values: Record<string, any>,
        initials: T,
    ): T {
        let shallowInitials = { ...initials };

        for (const key in shallowInitials) {
            if (typeof values[key] !== 'undefined') {
                shallowInitials[key] = values[key];
            }
        }

        return shallowInitials;
    }

    static createCountFormatter(
        number: number,
        words: string[],
        options: createCountFormatterOptions = {},
    ) {
        const { withNumber = true, decimal = 1 } = options;

        const cases = [2, 0, 1, 1, 1, 2];
        const roundNumber = Math.floor(number);

        const string =
            words[
                roundNumber % 100 > 4 && roundNumber % 100 < 20
                    ? 2
                    : cases[roundNumber % 10 < 5 ? roundNumber % 10 : 5]
            ];

        if (withNumber) {
            const multiplier = Number('1'.padEnd(decimal + 1, '0'));
            const displayNumber = Math.floor(number * multiplier) / multiplier;

            return displayNumber + ' ' + string;
        }

        return string;
    }

    static downloadUrl = async (url: string) => {
        const a = document.createElement('a');

        a.href = url;
        a.download = 'download';

        const clickHandler = () => {
            setTimeout(() => {
                a.removeEventListener('click', clickHandler);
            }, 150);
        };

        a.addEventListener('click', clickHandler, false);

        a.click();

        return a;
    };

    static getBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    static shapeDate = (dateString: any): Date | null => {
        const date = new Date(dateString);

        if (!isValid(date)) {
            return null;
        }

        return date;
    };
}

export { CommonHelper };
