import { gql } from '@apollo/client';

export const GetJivositeInfo = gql`
    query GetJivositeInfo {
        getJivositeInfo {
            contactInfo {
                name
                phone
                email
            }
            customData {
                title
                content
            }
            userToken
        }
    }
`;
