import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CourseEntityList } from '@modules/courses/entities';
import { GetAccessOLCoursesQuery } from '@modules/courses/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetAccessOlCoursesQueryType } from '@modules/types/graphql';

const useAccessOLCourses = (options?: QueryHookOptions<GetAccessOlCoursesQueryType>) => {
    const queryResult = useQuery<GetAccessOlCoursesQueryType>(GetAccessOLCoursesQuery, options);

    const { entities: courses, count } = useEntity(
        () => new CourseEntityList(queryResult.data?.getAccessOLCourses ?? null),
        [queryResult.data],
    );

    const result = {
        courses,
        count,
        ...queryResult,
    };

    return result;
};

export { useAccessOLCourses };
