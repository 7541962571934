import { gql } from '@apollo/client';

import { LapFragment } from '../fragments';

export const GetCurrentLapQuery = gql`
    query GetCurrentLap {
        getCurrentLap {
            ...Lap
            integrations {
                eljur
            }
        }
    }
    ${LapFragment}
`;
