import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@avangard/ui/core';
import { ChangeLapFormik } from './change-lap-formik';

import type { ModalProps } from '@avangard/ui/core';

type ChangeLapFormModalProps = Omit<ModalProps, 'title' | 'children'>;

const ChangeLapFormModal = (props: ChangeLapFormModalProps): React.ReactElement => {
    const { onClose, ...otherProps } = props;

    const { t } = useTranslation(['lap']);

    return (
        <Modal title={t('lap:actions.change_lap') ?? ''} onClose={onClose} {...otherProps}>
            <ChangeLapFormik onCancel={onClose} />
        </Modal>
    );
};

export { ChangeLapFormModal };
