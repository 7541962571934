import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { CourseEntityList } from '@modules/courses/entities';
import { GetCourseCollectionQuery } from '@modules/courses/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetCourseCollectionQueryType,
    GetCourseCollectionQueryVariables,
} from '@modules/types/graphql';

const useCourseCollection = (
    options?: QueryHookOptions<GetCourseCollectionQueryType, GetCourseCollectionQueryVariables>,
) => {
    const queryResult = useQuery<GetCourseCollectionQueryType, GetCourseCollectionQueryVariables>(
        GetCourseCollectionQuery,
        options,
    );

    const { entities: courses, count } = useEntity(
        () =>
            new CourseEntityList(
                queryResult.data?.getOLCourseCollection?.items ?? null,
                queryResult.data?.getOLCourseCollection?.page?.total,
            ),
        [queryResult.data],
    );

    const result = {
        courses,
        count,
        ...queryResult,
    };

    return result;
};

export { useCourseCollection };
