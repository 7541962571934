import React from 'react';
import styled from 'styled-components';
import { VictoryChart, VictoryGroup, VictoryBar, VictoryAxis } from 'victory';

import { chartTheme, chartProps } from './chart-theme';
import { ChartBaseTooltip } from './chart-base-tooltip';

import type {
    VictoryChartProps,
    VictoryGroupProps,
    VictoryBarProps,
    VictoryAxisProps,
} from 'victory';

type BarChartProps = {
    data: any[];
    type?: 'single' | 'group';
    ChartProps?: VictoryChartProps;
    GroupProps?: VictoryGroupProps;
    BarProps?: VictoryBarProps;
    AxisProps?: {
        independent?: VictoryAxisProps;
        dependent?: VictoryAxisProps;
    };
    disableIndependentAxis?: boolean;
    disableDependentAxis?: boolean;
};

const Chart = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    min-height: 245px;
`;

const BarChart = React.memo((props: BarChartProps): React.ReactElement => {
    const {
        data,
        type = 'single',
        ChartProps,
        GroupProps = {},
        BarProps = {},
        AxisProps = {},
        disableIndependentAxis,
        disableDependentAxis,
        ...otherProps
    } = props;

    const chartRef = React.useRef<HTMLDivElement>(null);
    const [chartWidth, setChartWidth] = React.useState(0);

    const combinedChartProps = {
        ...chartProps.chart,
        ...ChartProps,
    };

    const { labelComponent, ...otherBarProps } = BarProps;

    const combinedBarProps = {
        labelComponent: labelComponent || <ChartBaseTooltip />,
        ...chartProps.bar,
        ...otherBarProps,
    };

    React.useEffect(() => {
        const chartNode = chartRef?.current;

        if (chartNode && chartNode.clientWidth > 0 && chartWidth !== chartNode.clientWidth) {
            setChartWidth(chartNode.clientWidth);
        }
    }, [chartRef.current]);

    return (
        <Chart ref={chartRef} {...otherProps}>
            {chartWidth ? (
                <VictoryChart width={chartWidth} {...combinedChartProps}>
                    {type === 'single' ? (
                        <VictoryBar data={data} {...combinedBarProps} />
                    ) : (
                        <VictoryGroup {...GroupProps}>
                            {data.map((dataUnit, key) => (
                                <VictoryBar key={key} data={dataUnit} {...combinedBarProps} />
                            ))}
                        </VictoryGroup>
                    )}

                    {disableIndependentAxis ? null : (
                        <VictoryAxis
                            style={chartTheme.independentAxis}
                            {...AxisProps.independent}
                        />
                    )}

                    {disableDependentAxis ? null : (
                        <VictoryAxis
                            dependentAxis
                            style={chartTheme.dependentAxis}
                            {...AxisProps.dependent}
                        />
                    )}
                </VictoryChart>
            ) : null}
        </Chart>
    );
});

export { BarChart };
