import { gql } from '@apollo/client';

export const GetAccessOLCoursesQuery = gql`
    query GetAccessOLCourses {
        getAccessOLCourses {
            id
            name
        }
    }
`;
