import { gql } from '@apollo/client';

import { PageReplyFragment } from '@modules/layout/graphql';
import { CourseCollectionFragment } from '../fragments';

export const GetCourseCollectionQuery = gql`
    query GetCourseCollection($args: OLCourseCollectionArgs) {
        getOLCourseCollection(args: $args) {
            items {
                ...CourseCollection
            }
            page {
                ...PageReply
            }
        }
    }
    ${CourseCollectionFragment}
    ${PageReplyFragment}
`;
