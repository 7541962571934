import { gql } from '@apollo/client';

import { PageReplyFragment } from '@modules/layout/graphql';
import { LapFragment } from '../fragments';

export const GetLapCollectionQuery = gql`
    query GetLapCollection($args: LapCollectionArgs) {
        getLapCollection(args: $args) {
            items {
                ...Lap
            }
            page {
                ...PageReply
            }
        }
    }
    ${PageReplyFragment}
    ${LapFragment}
`;
