import React from 'react';

import { ReactComponent as ExternalPageLogoIcon } from './source/external-page-logo.svg';
import { Root } from './shared/styled';

import type { IconProps } from './shared/types';

const ExternalPageLogo = (props: IconProps): React.ReactElement => {
    return (
        <Root {...props}>
            <ExternalPageLogoIcon />
        </Root>
    );
};

export { ExternalPageLogo };
