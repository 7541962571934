import React from 'react';

import { useRegions, useLazyLocations } from '@modules/shared/hooks';

type UseGeoFiltersOptions = {
    refRegionId?: string;
};

const useGeoFilters = (options: UseGeoFiltersOptions) => {
    const { refRegionId } = options;

    const { regions, count: regionsCount, loading: regionsLoading } = useRegions();
    const [getLocations, { locations, count: locationsCount, loading: locationsLoading }] =
        useLazyLocations();

    React.useEffect(() => {
        if (!refRegionId) {
            return;
        }

        getLocations({
            variables: { regionId: refRegionId },
        });
    }, [refRegionId]);

    const result = {
        regions,
        regionsCount,
        regionsLoading,

        locations,
        locationsCount,
        locationsLoading,
        getLocations,
    };

    return result;
};

export { useGeoFilters };
