import { useQuery } from '@apollo/client';

import { GetSchoolTypesQuery } from '@modules/shared/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetSchoolTypesQueryType, GetSchoolTypesQueryVariables } from '@modules/types/graphql';

const useSchoolTypes = (
    options?: QueryHookOptions<GetSchoolTypesQueryType, GetSchoolTypesQueryVariables>,
) => {
    const queryResult = useQuery<GetSchoolTypesQueryType, GetSchoolTypesQueryVariables>(
        GetSchoolTypesQuery,
        options,
    );

    const schoolTypes = queryResult.data?.getSchoolTypes ?? [];

    const result = {
        schoolTypes,
        ...queryResult,
    };

    return result;
};

export { useSchoolTypes };
