import { routes, RouteMenuLocationEnum, RouteMenuSectionEnum } from '@config/routes';

import type { Route } from '@config/routes';
import type { UserEntity } from '@modules/user/entities';

type GetMenuRoutesOptions = {
    forUser: UserEntity;
    forSection?: RouteMenuSectionEnum;
    orderByOriented?: 'lap-first' | 'regional-center-first';
};

const filterBySection = (routes: Route[], section: RouteMenuSectionEnum) =>
    routes.filter(route => route.menuSections?.includes(section));

export const getMenuRoutes = (
    location: RouteMenuLocationEnum,
    options?: GetMenuRoutesOptions,
): Route[] => {
    let menuRoutes = Object.values(routes).filter(
        route =>
            typeof route.menuLocations !== 'undefined' && route.menuLocations.includes(location),
    );

    if (options?.forUser) {
        const currentRole = options.forUser.getCurrentRole();
        const allowed = options.forUser.getAllowedRoutes();

        menuRoutes = menuRoutes
            .filter(route => {
                let needCategoryGrant = route.categoryGrant;
                if (currentRole && route.categoryGrantFor?.[currentRole]) {
                    needCategoryGrant = route.categoryGrantFor[currentRole];
                }

                if (!needCategoryGrant) {
                    return true;
                }

                return allowed.includes(needCategoryGrant);
            })
            .filter(route => {
                if (!route.roles || !currentRole) {
                    return true;
                }

                return route.roles.includes(currentRole);
            });
    }

    if (options?.forSection) {
        menuRoutes = filterBySection(menuRoutes, options.forSection);
    }

    if (options?.orderByOriented) {
        const sortRule = options.orderByOriented;

        const lapOrientedRoutes = filterBySection(menuRoutes, RouteMenuSectionEnum.lapDataOriented);
        const regionalCenterOrientedRoutes = filterBySection(
            menuRoutes,
            RouteMenuSectionEnum.regionalCenterDataOriented,
        );

        if (sortRule === 'lap-first') {
            menuRoutes = [...lapOrientedRoutes, ...regionalCenterOrientedRoutes];
        }

        if (sortRule === 'regional-center-first') {
            menuRoutes = [...regionalCenterOrientedRoutes, ...lapOrientedRoutes];
        }
    }

    return menuRoutes;
};
