import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { useEnqueueStacks } from '@modules/layout/hooks';
import { CreateLapAccessRequest } from '@modules/lap/requests';
import { CreateLapAccessMutation } from '@modules/lap/graphql';
import { LapAccessFormik } from './lap-access-formik';

import type { CreateLapAccessFormValues } from '@modules/lap/requests';
import type {
    LapAccessTarget,
    CreateLapAccessMutationType,
    CreateLapAccessMutationVariables,
} from '@modules/types/graphql';

export type LapAccessCreateFormProps = {
    target?: LapAccessTarget;
    targetId?: string;
    onCancel: () => void;
    onCreateLapAccess?: () => Promise<void>;
    children: React.ReactNode;
};

const LapAccessCreateForm = (props: LapAccessCreateFormProps): React.ReactElement => {
    const { target, targetId, onCancel, onCreateLapAccess, children } = props;

    const { t } = useTranslation(['access']);

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [createLapAccess] = useMutation<
        CreateLapAccessMutationType,
        CreateLapAccessMutationVariables
    >(CreateLapAccessMutation);

    const initialValues: CreateLapAccessFormValues = {
        neededCopyFromLap: false,
        platform: '',
        course: null,
        lap: null,
        fromLap: null,
    };

    const handleSubmit = async (values: CreateLapAccessFormValues): Promise<void> => {
        try {
            const item = new CreateLapAccessRequest(values, { target, targetId });

            const { data: createLapAccessData } = await createLapAccess({
                variables: { item },
            });

            if (createLapAccessData?.createLapAccess?.id) {
                enqueueSuccess(t('access:notifiers.add_access.success'));

                if (onCreateLapAccess) {
                    await onCreateLapAccess();
                }

                onCancel();
            } else {
                enqueueError(t('access:notifiers.add_access.error'));
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <LapAccessFormik<CreateLapAccessFormValues>
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {children}
        </LapAccessFormik>
    );
};

export { LapAccessCreateForm };
