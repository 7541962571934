import styled from 'styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Checkbox, withStyles, FormGroup, FormControlLabel } from '@material-ui/core';
import {
    Autocomplete,
    Button,
    DatepickerSinglePicker,
    DatepickerTextField,
    OnDateChangeProps,
} from '@avangard/ui/core';

import { useEnqueueStacks } from '@modules/layout/hooks';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { UpdateLapRequest } from '@modules/lap/requests';
import { UpdateLapMutation, GetLapCollectionQuery } from '@modules/lap/graphql';
import { CourseEntity } from '@modules/courses/entities';
import { useCourses } from '@modules/courses/hooks';

import type { LapEntity } from '@modules/lap/entities';
import type { UpdateLapFormValues } from '@modules/lap/requests';
import type { UpdateLapMutationType, UpdateLapMutationVariables } from '@modules/types/graphql';

type LapEditFormProps = {
    canEdit: boolean;
    lap: LapEntity;
    onCancel: () => void;
};

const StyledFormGroup = withStyles({
    root: {
        marginTop: -8,
        marginInlineEnd: -4,
        marginBottom: '12px!important',

        '& > *': {
            width: '100%',
        },
    },
})(FormGroup);

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 24px;
        }
    }
`;

const FormRowSubmit = styled(FormStyled.FormRowSubmit)`
    justify-content: flex-end;
`;

const LapEditForm = (props: LapEditFormProps) => {
    const { canEdit, lap, onCancel } = props;

    const { t } = useTranslation(['common', 'errors', 'lap']);

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { courses } = useCourses();

    const [updateLap] = useMutation<UpdateLapMutationType, UpdateLapMutationVariables>(
        UpdateLapMutation,
    );

    const initialValues: UpdateLapFormValues = {
        course: lap.course,
        from: lap.getDate(lap.from),
        to: lap.getDate(lap.to),
        isFree: lap.isFree,
        canCertificate: lap.canCertificate,
    };

    return (
        <ExtendedFormik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                from: yup
                    .date()
                    .nullable()
                    .required(t('errors:validations.required') ?? ''),
                to: yup
                    .date()
                    .nullable()
                    .required(t('errors:validations.required') ?? '')
                    .min(yup.ref('from'), t('errors:time.invalid_range') ?? ''),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const item = new UpdateLapRequest(values);

                    const { data: updateLapData } = await updateLap({
                        variables: { id: lap.id, item },
                        refetchQueries: [{ query: GetLapCollectionQuery }],
                        awaitRefetchQueries: true,
                    });

                    const lapId = updateLapData?.updateLap?.id;

                    if (lapId) {
                        enqueueSuccess(t('lap:notifiers.edit_lap.success'));
                        onCancel();
                    } else {
                        enqueueError(t('lap:notifiers.edit_lap.error'));
                    }
                } catch (e) {
                    console.log(e);
                    throw e;
                }
            }}
        >
            {formikProps => {
                const { values, errors, isSubmitting, setFieldValue, handleChange } = formikProps;

                const handleChangeDate = (field: string) => (data: OnDateChangeProps) =>
                    setFieldValue(field, data.date);

                return (
                    <StyledForm>
                        <Autocomplete<CourseEntity | null, false, false, false>
                            fullWidth
                            disabled
                            id='course'
                            label={t('common:forms.labels.course') ?? ''}
                            placeholder={t('common:forms.placeholder.choose_course') ?? ''}
                            options={courses}
                            value={values.course}
                            error={!!errors.course}
                            helperText={errors.course}
                            getOptionLabel={option => option?.name ?? '-'}
                        />

                        <DatepickerSinglePicker
                            date={values.from}
                            onDateChange={handleChangeDate('from')}
                        >
                            {datepickerProps => {
                                const { inputProps, inputComponent } = datepickerProps;

                                return (
                                    <DatepickerTextField
                                        id='from'
                                        label={t('common:forms.labels.start_lap_date')}
                                        placeholder={t('common:forms.labels.date') ?? ''}
                                        disabled={!canEdit}
                                        error={!!errors.from}
                                        helperText={errors.from}
                                        InputProps={{ inputComponent }}
                                        {...inputProps}
                                    />
                                );
                            }}
                        </DatepickerSinglePicker>

                        <DatepickerSinglePicker
                            date={values.to}
                            onDateChange={handleChangeDate('to')}
                        >
                            {datepickerProps => {
                                const { inputProps, inputComponent } = datepickerProps;

                                return (
                                    <DatepickerTextField
                                        id='to'
                                        label={t('common:forms.labels.end_lap_date')}
                                        placeholder={t('common:forms.labels.date') ?? ''}
                                        disabled={!canEdit}
                                        error={!!errors.to}
                                        helperText={errors.to}
                                        InputProps={{ inputComponent }}
                                        {...inputProps}
                                    />
                                );
                            }}
                        </DatepickerSinglePicker>

                        <StyledFormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name='isFree'
                                        disabled={!canEdit}
                                        checked={values.isFree}
                                        onChange={handleChange}
                                    />
                                }
                                label={t('lap:form.labels.free')}
                            />
                        </StyledFormGroup>

                        <StyledFormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name='canCertificate'
                                        disabled={!canEdit}
                                        checked={values.canCertificate}
                                        onChange={handleChange}
                                    />
                                }
                                label={t('lap:form.labels.certificate')}
                            />
                        </StyledFormGroup>

                        <FormRowSubmit>
                            <Button variant='outlined' disabled={isSubmitting} onClick={onCancel}>
                                {t('common:actions.cancel')}
                            </Button>

                            {canEdit ? (
                                <Button type='submit' loading={isSubmitting}>
                                    {t('common:actions.force_change')}
                                </Button>
                            ) : null}
                        </FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { LapEditForm };
