import { gql } from '@apollo/client';

import { CourseCollectionFragment } from '../fragments';

export const GetCoursesQuery = gql`
    query GetCourses {
        getOLCourses {
            ...CourseCollection
        }
    }
    ${CourseCollectionFragment}
`;
