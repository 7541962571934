import { gql } from '@apollo/client';

import { SchoolSingleFragment } from '../fragments';

export const GetSchoolQuery = gql`
    query GetSchool($id: ID!) {
        getSchool(id: $id) {
            ...SchoolSingle
        }
    }
    ${SchoolSingleFragment}
`;
