import React from 'react';

type EmptyLayoutContextValues = {
    pageTitle: string;
    setPageTitle: (state: string | ((state: string) => string)) => void;
};

type EmptyLayoutProviderProps = {
    pageTitle: string;
    children: React.ReactNode;
};

const EmptyLayoutContext = React.createContext<EmptyLayoutContextValues | undefined>(undefined);

const EmptyLayoutProvider = (props: EmptyLayoutProviderProps): React.ReactElement => {
    const { pageTitle: pageTitleProps, children } = props;

    const [pageTitle, setPageTitle] =
        React.useState<EmptyLayoutContextValues['pageTitle']>(pageTitleProps);

    React.useEffect(() => {
        setPageTitle(pageTitleProps);
    }, [pageTitleProps]);

    React.useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

    return (
        <EmptyLayoutContext.Provider
            value={{
                pageTitle,
                setPageTitle,
            }}
        >
            {children}
        </EmptyLayoutContext.Provider>
    );
};

const useEmptyLayout = (): EmptyLayoutContextValues => {
    const context = React.useContext(EmptyLayoutContext);

    if (context === undefined) {
        throw new Error('empty layout context value are undefined');
    }

    return context;
};

export { EmptyLayoutContext, EmptyLayoutProvider, useEmptyLayout };
