import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';
import { ImageCropInfoEntity } from './image-crop-info-entity';

import type { FileInfo } from '@modules/types/graphql';

export type Entity = Partial<FileInfo> | null;

@DecribeEntity('FileInfoEntity')
class FileInfoEntity extends BaseEntity {
    id: string;
    name: string;
    mime: string;
    origin: string;
    thumbnail: string;
    croppedThumbnail: string;
    createdAt: string;

    @Relation(() => ImageCropInfoEntity)
    cropInfo: ImageCropInfoEntity;

    constructor(fileInfo: Entity) {
        super(fileInfo);

        this.id = fileInfo?.id ?? '';
        this.name = fileInfo?.name ?? '';
        this.mime = fileInfo?.mime ?? '';
        this.origin = fileInfo?.origin ?? '';
        this.thumbnail = fileInfo?.thumbnail ?? '';
        this.croppedThumbnail = fileInfo?.croppedThumbnail ?? '';
        this.createdAt = fileInfo?.createdAt ?? '';
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }
}

export { FileInfoEntity };
