import { createStore, createEvent } from 'effector';
import { useStore } from 'effector-react';

import type { UserGrantsTableItemEntity } from '@modules/settings/entities';

type EditGrantsStore = {
    enabled: boolean;
    grants: ReturnType<UserGrantsTableItemEntity['toJSON']>[];
};

// prettier-ignore
const addGrantEvent = createEvent<EditGrantsStore['grants'][number]>('add role token (update if exists)');
const enableEditGrantsEvent = createEvent('enable grants edit mode');
const resetEditGrantsEvent = createEvent('reset grants edit mode');
const $editGrantsStore = createStore<EditGrantsStore>({
    enabled: false,
    grants: [],
});

const useEditGrants = (): EditGrantsStore => useStore($editGrantsStore);

export {
    $editGrantsStore,
    useEditGrants,
    addGrantEvent,
    enableEditGrantsEvent,
    resetEditGrantsEvent,
};
