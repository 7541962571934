import { useLazyQuery } from '@apollo/client';

import { GetSchoolTypesQuery } from '@modules/shared/graphql';

import type { QueryLazyOptions, LazyQueryResult, QueryHookOptions } from '@apollo/client';
import type {
    SchoolType,
    GetSchoolTypesQueryType,
    GetSchoolTypesQueryVariables,
} from '@modules/types/graphql';

type Tuple = [
    (options?: QueryLazyOptions<GetSchoolTypesQueryVariables>) => void,
    LazyQueryResult<GetSchoolTypesQueryType, GetSchoolTypesQueryVariables> & {
        schoolTypes: SchoolType[];
    },
];

const useLazySchoolTypes = (
    options?: QueryHookOptions<GetSchoolTypesQueryType, GetSchoolTypesQueryVariables>,
): Tuple => {
    const [getSchoolTypes, queryResult] = useLazyQuery<
        GetSchoolTypesQueryType,
        GetSchoolTypesQueryVariables
    >(GetSchoolTypesQuery, options);

    const schoolTypes = queryResult.data?.getSchoolTypes ?? [];

    return [getSchoolTypes, { schoolTypes, ...queryResult }];
};

export { useLazySchoolTypes };
