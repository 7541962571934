import styled, { css } from 'styled-components';
import { makeStyles, Tooltip } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { breakpoints } from '@avangard/ui/utils';

import type { RegionalCenterEntity } from '@modules/regional-center/entities';

type RegionalCenterRegionsInfoProps = {
    regionalCenter: RegionalCenterEntity;
    className?: string;
};

const useTooltipClasses = makeStyles({
    tooltip: {
        maxWidth: 325,
    },
});

const Root = styled.div<{ withIcon?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;

    > p {
        display: inline-block;
        text-align: left;

        ${p =>
            p.withIcon &&
            css`
                &:first-child {
                    max-width: calc(100% - 32px);
                    white-space: pre;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                &:last-child {
                    color: rgba(255, 255, 255, 0.8);

                    > span {
                        margin-inline-start: 8px;
                        cursor: pointer;
                    }
                }
            `}
    }

    > *:not(:last-child) {
        margin-bottom: 4px;
    }

    ${breakpoints.down('xs')} {
        justify-content: center;
    }
`;

const infoIcon = <ErrorOutline style={{ fontSize: 18 }} />;
const regionsCountForDisplayTooltip = 2;

const RegionalCenterRegionsInfo = (props: RegionalCenterRegionsInfoProps) => {
    const { regionalCenter, ...otherProps } = props;

    const tooltipClasses = useTooltipClasses();

    const needTooltip = regionalCenter.regions.length > regionsCountForDisplayTooltip;

    return (
        <Root withIcon={needTooltip} {...otherProps}>
            <p>{regionalCenter.regionsToString(regionsCountForDisplayTooltip)}</p>

            {needTooltip ? (
                <p>
                    <Tooltip
                        arrow
                        classes={tooltipClasses}
                        title={regionalCenter.regionsToString()}
                    >
                        <span>{infoIcon}</span>
                    </Tooltip>
                </p>
            ) : null}
        </Root>
    );
};

export { RegionalCenterRegionsInfo };
