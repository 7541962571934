import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableSortLabel } from '@modules/layout/moleculas';
import { TableCell } from './table-cell';

import type { Sort } from '@modules/types/graphql';
import type { TableCellType } from './table';

type TableHeadCellProps = {
    column: TableCellType;
    onClick: (event: React.MouseEvent<HTMLSpanElement>) => void;
    active?: boolean;
    direction?: Sort;
};

const TableHeadCell = (props: TableHeadCellProps): React.ReactElement => {
    const { column, active, direction, onClick, ...otherProps } = props;

    const { t } = useTranslation('common');

    const renderedLabel = React.useMemo(() => {
        if (!column.label) {
            return '';
        }

        const result = column.label.includes('common:') ? t(column.label) : column.label;

        return result;
    }, [t, column.label]);

    if (column.sortable === false) {
        return (
            <TableCell width={column.width} align={column.align} {...otherProps}>
                {renderedLabel}
            </TableCell>
        );
    }

    return (
        <TableCell key={column.id} width={column.width} {...otherProps}>
            <TableSortLabel active={active} direction={direction} onClick={onClick}>
                {renderedLabel}
            </TableSortLabel>
        </TableCell>
    );
};

export { TableHeadCell };
