export { FileInfoFragment, FileDocFragment, VideoFileDocFragment } from './fragments';
export {
    GetLocationsQuery,
    GetSchoolTypesQuery,
    GetSchoolNumbersQuery,
    GetRegionsQuery,
    GetStudentSchoolClassesQuery,
} from './queries';
export { UploadFileRestMutation, UpdateFileCropMutation } from './mutations';

export type { UploadFileRestMutationType } from './mutations';
