import React from 'react';

import { useLazyStudentSchoolClasses } from '@modules/shared/hooks';

const useStudentSchoolClassesFilter = () => {
    const [getStudentSchoolClasses, { schoolClasses, loading: schoolClassesLoading }] =
        useLazyStudentSchoolClasses();

    const schoolClassesCount = schoolClasses?.length ?? 0;
    const schoolClassesList = schoolClasses.map(number => ({ key: number, value: String(number) }));

    React.useEffect(() => {
        getStudentSchoolClasses();
    }, []);

    return {
        schoolClasses,
        schoolClassesOptions: schoolClassesList,
        schoolClassesCount,
        schoolClassesLoading,
    };
};

export { useStudentSchoolClassesFilter };
