import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Maybe, SocialContacs } from '@modules/types/graphql';

export type Entity = Maybe<Partial<SocialContacs>>;

@DecribeEntity('SocialsEntity')
class SocialsEntity extends BaseEntity {
    vk: string;

    constructor(socials: Entity) {
        super(socials);

        this.vk = socials?.vk ?? '';
    }
}

export { SocialsEntity };
