import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@avangard/ui/utils';
import { useTranslation } from 'react-i18next';

import { EmptyLayoutProvider } from './context';

type EmptyLayoutProps = {
    pageTitle: string;
    children?: React.ReactElement;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-height: 100vh;

    ${breakpoints.down('sm')} {
        min-height: 100%;

        @supports (height: 100dvh) {
            min-height: 100dvh;
        }
    }
`;

const EmptyLayout = (props: EmptyLayoutProps): React.ReactElement => {
    const { pageTitle: pageTitleProps, children } = props;

    const { t } = useTranslation('common');

    return (
        <EmptyLayoutProvider pageTitle={pageTitleProps ? t(pageTitleProps) : ''}>
            <Root>{children}</Root>
        </EmptyLayoutProvider>
    );
};

export { EmptyLayout };
