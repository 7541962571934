import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import { Drawer, Button } from '@avangard/ui/core';

import { useEvent } from '@modules/shared/hooks';
import { usePageFilters } from './page-filters-context';
import { usePageFiltersDrawer } from './page-filters-drawer-context';

type DrawerFiltersProps = {
    children: React.ReactNode;
    title?: string | null;
    renderFooter?: any;
};

const ButtonReset = withStyles({
    root: {
        marginInlineEnd: 8,
    },
})(Button);

const FilterColumn = styled.div`
    display: flex;
    flex-wrap: wrap;

    > * {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
`;

const PageFiltersDrawer = (props: DrawerFiltersProps): React.ReactElement => {
    const { children, title, renderFooter } = props;

    const { t } = useTranslation(['common']);

    const { open, onClose } = usePageFiltersDrawer();
    const { drawerFiltersCount, resetFilters, handleSubmit } = usePageFilters();

    const handleResetFilters = useEvent(() => resetFilters('drawer'));

    const baseRenderedFooter = React.useMemo(
        () => (
            <>
                {drawerFiltersCount !== 0 ? (
                    <ButtonReset variant='outlined' onClick={handleResetFilters}>
                        {t('common:actions.reset')}
                    </ButtonReset>
                ) : null}

                <Button onClick={handleSubmit}>{t('common:actions.submit_filters')}</Button>
            </>
        ),
        [drawerFiltersCount],
    );

    return (
        <Drawer
            title={title ?? t('common:blocks.filters.title')}
            open={open}
            renderFooter={renderFooter ?? baseRenderedFooter}
            onClose={onClose}
        >
            {typeof children === 'function' ? children : <FilterColumn>{children}</FilterColumn>}
        </Drawer>
    );
};

export { PageFiltersDrawer };
