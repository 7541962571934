import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@avangard/ui/core';
import { breakpoints } from '@avangard/ui/utils';

import { ListToolbar } from '@modules/layout/moleculas';

type ListFiltersProps = {
    children: React.ReactNode;
    className?: string;
    issetFilters?: boolean;
    hideClearButton?: boolean;
    onResetFilters?: () => void;
};

const List = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    > * {
        min-width: 215px;
        margin-bottom: 10px;

        &:not(:last-child) {
            margin-inline-end: 8px;

            ${breakpoints.down('xs')} {
                margin-inline-end: 0;
                margin-bottom: 10px;
            }
        }

        ${breakpoints.down('md')} {
            min-width: auto;
        }

        ${breakpoints.down('xs')} {
            min-width: 100%;
        }
    }

    ${breakpoints.down('md')} {
        width: 100%;
    }
`;

const ResetFilters = styled.div`
    display: block;
`;

const ListFilters = (props: ListFiltersProps): React.ReactElement => {
    const { hideClearButton = true, issetFilters, onResetFilters, children } = props;

    const { t } = useTranslation(['common']);

    return (
        <ListToolbar>
            <List>{children}</List>

            {!hideClearButton && issetFilters ? (
                <ResetFilters>
                    <Button onClick={onResetFilters}>{t('common:actions.reset_filters')}</Button>
                </ResetFilters>
            ) : null}
        </ListToolbar>
    );
};

export { ListFilters };
