import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Modal } from '@avangard/ui/core';

import type { ModalProps } from '@avangard/ui/core';

type LapFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    title: string;
    children: React.ReactNode;
};

const useModalStyles = makeStyles({
    paper: {
        overflow: 'visible',
    },
});

const LapFormModal = (props: LapFormModalProps): React.ReactElement => {
    const { onClose, title, children, ...otherProps } = props;

    const modalClasses = useModalStyles();

    return (
        <Modal classes={modalClasses} title={title} onClose={onClose} {...otherProps}>
            {children}
        </Modal>
    );
};

export { LapFormModal };
