import { gql } from '@apollo/client';

import { SchoolCollectionFragment } from '../fragments';

export const CreateSchoolMutation = gql`
    mutation CreateSchool($item: SchoolArgs!) {
        createSchool(item: $item) {
            ...SchoolCollection
        }
    }
    ${SchoolCollectionFragment}
`;
