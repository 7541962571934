import { gql } from '@apollo/client';

import { PageReplyFragment } from '@modules/layout/graphql';
import { SchoolCollectionFragment } from '../fragments';

export const GetSchoolCollectionQuery = gql`
    query GetSchoolCollection($args: SchoolCollectionArgs) {
        getSchoolCollection(args: $args) @connection(key: "getSchoolCollection", filter: ["args"]) {
            items {
                ...SchoolCollection
            }
            page {
                ...PageReply
            }
        }
    }
    ${SchoolCollectionFragment}
    ${PageReplyFragment}
`;
