import { BaseEntity, Entity as DecribeEntity, Relation, RelationList } from '@lib/entity';
import { OnlineStudentPerformanceCourseEntity } from './online-student-performance-course.entity';
import { OnlineStudentPerformanceSubjectEntityList } from './online-student-performance-subject.entity-list';

import type { OnlineStudentPerformance } from '@modules/types/graphql';
import type { OnlineStudentPerformanceSubjectEntity } from './online-student-performance-subject.entity';

export type Entity = Partial<OnlineStudentPerformance> | null;

@DecribeEntity('OnlineStudentPerformanceEntity')
class OnlineStudentPerformanceEntity extends BaseEntity {
    @Relation(() => OnlineStudentPerformanceCourseEntity)
    course: OnlineStudentPerformanceCourseEntity;

    @RelationList(() => OnlineStudentPerformanceSubjectEntityList)
    subjects: OnlineStudentPerformanceSubjectEntity[];
}

export { OnlineStudentPerformanceEntity };
