import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@avangard/ui/core';

import { LapEntity } from '@modules/lap/entities';
import { LapEditForm, LapFormModal } from '@modules/lap/organisms';

type LessonPlaceCreateFormProps = {
    canEdit: boolean;
    lap: LapEntity;
};

const LapEdit = (props: LessonPlaceCreateFormProps) => {
    const { canEdit, lap } = props;

    const { t } = useTranslation(['common', 'lap']);

    const [openLapEditFormModal, setLapEditFormModal] = React.useState(false);

    const handleOpenLapEditFormModal = (): void => setLapEditFormModal(true);
    const handleCloseLapEditFormModal = (): void => setLapEditFormModal(false);

    return (
        <>
            <LapFormModal
                title={t('lap:edit_lap.title')}
                open={openLapEditFormModal}
                onClose={handleCloseLapEditFormModal}
            >
                <LapEditForm canEdit={canEdit} lap={lap} onCancel={handleCloseLapEditFormModal} />
            </LapFormModal>

            <Button variant='text' onClick={handleOpenLapEditFormModal}>
                {t('common:actions.force_change')}
            </Button>
        </>
    );
};

export { LapEdit };
