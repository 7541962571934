import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';

import { EljurIntegrationEntity } from '@modules/eljur/entities';
import type { Maybe, StudentInteration } from '@modules/types/graphql';

export type Entity = Maybe<Partial<StudentInteration>>;

@DecribeEntity('IntegrationEntity')
class IntegrationEntity extends BaseEntity {
    @Relation(() => EljurIntegrationEntity)
    eljur: EljurIntegrationEntity;
}

export { IntegrationEntity };
