import { useLazyQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { SchoolEntityList } from '@modules/schools/entities';
import { GetSchoolCollectionLazyQuery } from '@modules/schools/graphql';

import type { QueryLazyOptions, LazyQueryResult, QueryHookOptions } from '@apollo/client';
import type { SchoolEntity } from '@modules/schools/entities';
import type {
    GetSchoolCollectionLazyQueryType,
    GetSchoolCollectionLazyQueryVariables,
} from '@modules/types/graphql';

type Tuple = [
    (options?: QueryLazyOptions<GetSchoolCollectionLazyQueryVariables>) => void,
    LazyQueryResult<GetSchoolCollectionLazyQueryType, GetSchoolCollectionLazyQueryVariables> & {
        schools: SchoolEntity[];
        count: number;
    },
];

const useLazySchools = (
    options?: QueryHookOptions<
        GetSchoolCollectionLazyQueryType,
        GetSchoolCollectionLazyQueryVariables
    >,
): Tuple => {
    const [getSchools, queryResult] = useLazyQuery<
        GetSchoolCollectionLazyQueryType,
        GetSchoolCollectionLazyQueryVariables
    >(GetSchoolCollectionLazyQuery, { fetchPolicy: 'network-only', ...options });

    const { entities: schools, count } = useEntity(
        () => new SchoolEntityList(queryResult.data?.getSchoolCollection?.items ?? []),
        [queryResult.data],
    );

    return [getSchools, { schools, count, ...queryResult }];
};

export { useLazySchools };
