import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Checkbox, MenuItem, withStyles, FormGroup, FormControlLabel } from '@material-ui/core';
import { Autocomplete, TextField, Button } from '@avangard/ui/core';

import { FormStyled } from '@modules/layout/styled';
import { Form } from '@modules/layout/organisms';
import { platforms } from '@modules/lap/constants';
import { useLazyLaps } from '@modules/lap/hooks';
import { useCourses } from '@modules/courses/hooks';
import { Platform } from '@modules/types/graphql';

import type { LapEntity, LapAccessEntity } from '@modules/lap/entities';
import type { CreateLapAccessFormValues, UpdateLapAccessFormValues } from '@modules/lap/requests';
import type { CourseEntity } from '@modules/courses/entities';

type LapAccessFormProps = {
    lapAccessList?: (LapAccessEntity | null)[];
    onCancel: () => void;
    submitText?: string | null;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 24px;
        }
    }
`;

const StyledFormGroup = withStyles({
    root: {
        marginTop: -8,
        marginInlineEnd: -4,
        marginBottom: '12px!important',

        '& > *': {
            width: '100%',
        },
    },
})(FormGroup);

const FormRowSubmit = styled(FormStyled.FormRowSubmit)`
    justify-content: flex-end;
`;

const LapAccessForm = (props: LapAccessFormProps) => {
    const { lapAccessList, submitText, onCancel } = props;

    const { t } = useTranslation(['common', 'access', 'lap']);

    const { values, errors, setFieldValue, handleChange, isSubmitting } = useFormikContext<
        CreateLapAccessFormValues | UpdateLapAccessFormValues
    >();

    const canChooseCourse = values.platform !== Platform.center;
    const canChooseLap = canChooseCourse ? values.platform && values.course : !!values.platform;

    const { courses, loading: coursesLoading } = useCourses();
    const [getLaps, { laps, loading: lapsLoading }] = useLazyLaps();

    const handleChangeAutocompleteField =
        <T extends any>(field: string) =>
        (_: React.ChangeEvent<{}>, value: T | null) =>
            setFieldValue(field, value);

    React.useEffect(() => {
        setFieldValue('lap', null);

        if (values.platform) {
            getLaps({
                variables: {
                    platform: values.platform,
                    courseId: values.course?.id,
                },
            });
        }

        if (!canChooseCourse) {
            setFieldValue('course', null);
        }
    }, [values.platform, values.course]);

    return (
        <StyledForm>
            <TextField
                select
                fullWidth
                id='platform'
                name='platform'
                label={t('common:forms.labels.platform')}
                placeholder={t('common:forms.placeholder.choose_platform') ?? ''}
                value={values.platform}
                error={!!errors.platform}
                helperText={errors.platform}
                onChange={handleChange}
            >
                <MenuItem disabled value=''>
                    {t('common:forms.placeholder.choose_platform')}
                </MenuItem>

                {platforms.map(platform => (
                    <MenuItem key={platform.key} value={platform.key}>
                        {t(platform.value)}
                    </MenuItem>
                ))}
            </TextField>

            <Autocomplete<CourseEntity | null, false, false, false>
                fullWidth
                id='course'
                label={t('common:forms.labels.course') ?? ''}
                placeholder={t('common:forms.placeholder.choose_course') ?? ''}
                options={courses}
                value={values.course}
                error={!!errors.course}
                helperText={errors.course}
                disabled={coursesLoading || !canChooseCourse}
                getOptionLabel={option => option?.name ?? '-'}
                onChange={handleChangeAutocompleteField<CourseEntity>('course')}
            />

            <Autocomplete<LapEntity | null, false, false, false>
                fullWidth
                id='lap'
                label={t('common:forms.labels.lap') ?? ''}
                placeholder={t('common:forms.placeholder.choose_lap') ?? ''}
                options={laps}
                value={values.lap}
                error={!!errors.lap}
                helperText={errors.lap}
                disabled={lapsLoading || !canChooseLap}
                getOptionLabel={option => option?.name ?? '-'}
                onChange={handleChangeAutocompleteField<LapEntity>('lap')}
            />

            {!!lapAccessList && lapAccessList.length > 0 && (
                <StyledFormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name='neededCopyFromLap'
                                checked={values.neededCopyFromLap}
                                onChange={handleChange}
                            />
                        }
                        label={t('lap:actions.copy_students_from_lap')}
                    />
                </StyledFormGroup>
            )}

            {!!lapAccessList && lapAccessList.length > 0 && values.neededCopyFromLap && (
                <Autocomplete<LapAccessEntity | null, false, false, false>
                    fullWidth
                    id='fromLap'
                    label={t('common:forms.labels.lap') ?? ''}
                    placeholder={t('common:forms.placeholder.choose_lap') ?? ''}
                    options={lapAccessList}
                    value={values.fromLap}
                    error={!!errors.fromLap}
                    helperText={errors.fromLap}
                    getOptionLabel={option => option?.lap?.getFormattedName() ?? '-'}
                    onChange={handleChangeAutocompleteField<LapAccessEntity>('fromLap')}
                />
            )}

            <FormRowSubmit>
                <Button variant='outlined' disabled={isSubmitting} onClick={onCancel}>
                    {t('common:actions.cancel')}
                </Button>

                <Button type='submit' loading={isSubmitting}>
                    {submitText ? submitText : t('common:actions.submit')}
                </Button>
            </FormRowSubmit>
        </StyledForm>
    );
};

export { LapAccessForm };
