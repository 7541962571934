import { EntityList } from '@lib/entity';
import { OnlineStudentPerformanceSubjectEntity } from './online-student-performance-subject.entity';

import type { Entity } from './online-student-performance-subject.entity';

class OnlineStudentPerformanceSubjectEntityList extends EntityList<OnlineStudentPerformanceSubjectEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(OnlineStudentPerformanceSubjectEntity, items, count);
    }
}

export { OnlineStudentPerformanceSubjectEntityList };
