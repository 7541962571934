import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { DNS_DOMAIN } from '@config/environment';

class Token {
    #token: string | null;

    static saveInStorage(field: string, token: string) {
        try {
            Cookie.set(field, token, { path: '/', domain: DNS_DOMAIN });
        } catch (e) {
            throw e;
        }
    }

    static destroyInStorage(field: string) {
        try {
            Cookie.remove(field, { path: '/', domain: DNS_DOMAIN });
        } catch (e) {
            throw e;
        }
    }

    constructor(tokenField: string) {
        this.#token = Cookie.get(tokenField) ?? null;
    }

    getToken() {
        return this.#token;
    }

    issetToken() {
        const isset = this.getToken() !== null;

        return isset;
    }

    isExpiredAfter(seconds: number) {
        const now = Math.ceil(Date.now() / 1000);
        const payload = this.decodePayload();

        if (payload === null) {
            return true;
        }

        return now + seconds >= payload.exp;
    }

    isExpired() {
        const now = Date.now() / 1000;
        const payload = this.decodePayload();

        if (payload === null) {
            return true;
        }

        return now >= payload.exp;
    }

    decodePayload(): Record<string, any> | null {
        const currentToken = this.getToken();

        if (currentToken === null) {
            return null;
        }

        const payload = jwtDecode(currentToken) as Record<string, any>;

        return payload;
    }
}

export { Token };
