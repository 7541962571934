import { format, endOfDay } from 'date-fns';

import type { CourseEntity } from '@modules/courses/entities';
import type { LapCreateArgs } from '@modules/types/graphql';

export type CreateLapFormValues = {
    from: Date | null;
    to: Date | null;
    course: CourseEntity | null;
    isFree: boolean;
    canCertificate: boolean;
};

class CreateLapRequest {
    from: LapCreateArgs['from'];
    to: LapCreateArgs['to'];
    courseId: LapCreateArgs['courseId'];
    isFree: LapCreateArgs['isFree'];
    canCertificate: LapCreateArgs['canCertificate'];

    constructor(values: CreateLapFormValues) {
        this.from = values.from ? format(endOfDay(values.from), 'yyyy-MM-dd') : undefined;
        this.to = values.to ? format(endOfDay(values.to), 'yyyy-MM-dd') : undefined;
        this.courseId = values.course?.id;
        this.isFree = values.isFree;
        this.canCertificate = values.canCertificate;
    }
}

export { CreateLapRequest };
