import { createCustomError } from '@lib/errors';
import { i18n } from '@lib/i18n';

import type { LapEntity, LapAccessEntity } from '@modules/lap/entities';
import type { CourseEntity } from '@modules/courses/entities';
import type { Platform, LapAccessUpdateArgs } from '@modules/types/graphql';

export type UpdateLapAccessFormValues = {
    neededCopyFromLap?: boolean;
    platform: Platform | '';
    course: CourseEntity | null;
    lap: LapEntity | null;
    fromLap: LapAccessEntity | null;
};

class UpdateLapAccessRequest {
    lapId: LapAccessUpdateArgs['lapId'];

    constructor(values: UpdateLapAccessFormValues) {
        if (!values.lap) {
            throw createCustomError(i18n.t('errors:lap.invalid'));
        }

        this.lapId = values.lap.id;
    }
}

export { UpdateLapAccessRequest };
