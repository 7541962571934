import React from 'react';
import { useSnackbar } from 'notistack';

import type { SnackbarMessage, OptionsObject } from 'notistack';

const useEnqueueStacks = () => {
    const { enqueueSnackbar } = useSnackbar();

    const enqueueDefault = React.useCallback(
        (message: SnackbarMessage, options?: OptionsObject) =>
            enqueueSnackbar(message, { variant: 'default', ...options }),
        [],
    );

    const enqueueError = React.useCallback(
        (message: SnackbarMessage, options?: OptionsObject) =>
            enqueueSnackbar(message, { variant: 'error', ...options }),
        [],
    );

    const enqueueSuccess = React.useCallback(
        (message: SnackbarMessage, options?: OptionsObject) =>
            enqueueSnackbar(message, { variant: 'success', ...options }),
        [],
    );

    const enqueueWarning = React.useCallback(
        (message: SnackbarMessage, options?: OptionsObject) =>
            enqueueSnackbar(message, { variant: 'warning', ...options }),
        [],
    );

    const enqueueInfo = React.useCallback(
        (message: SnackbarMessage, options?: OptionsObject) =>
            enqueueSnackbar(message, { variant: 'info', ...options }),
        [],
    );

    return React.useMemo(
        () => ({
            enqueueDefault,
            enqueueError,
            enqueueSuccess,
            enqueueWarning,
            enqueueInfo,
        }),
        [],
    );
};

export { useEnqueueStacks };
