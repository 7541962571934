import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { black, gray, white } from '@avangard/ui/colors';
import { breakpoints } from '@avangard/ui/utils';

import { HeaderExternalLayout } from '@modules/layout/organisms';
import { ExternalLayoutProvider } from './context';

type ExternalLayoutProps = {
    pageTitle: string;
    children?: React.ReactElement;
};

const footerHeight = '64px';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;

    ${breakpoints.down('sm')} {
        min-height: 100%;

        @supports (height: 100dvh) {
            min-height: 100dvh;
        }
    }
`;

const Content = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`;

const Footer = styled.div`
    flex: 0 0 auto;
    width: 100%;
    font-size: 1.4rem;
    font-weight: 400;
    color: ${black[100]};
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${white[100]};
    height: ${footerHeight};
    border-top: 1px solid ${gray[40]};
`;

const ExternalLayout = (props: ExternalLayoutProps): React.ReactElement => {
    const { pageTitle: pageTitleProps, children } = props;

    const { t } = useTranslation('common');

    return (
        <ExternalLayoutProvider title={pageTitleProps ? t(pageTitleProps) : ''}>
            <Root>
                <HeaderExternalLayout />
                <Content>{children}</Content>
                <Footer>
                    © {new Date().getFullYear()} {t('common:footer.copyright')}
                </Footer>
            </Root>
        </ExternalLayoutProvider>
    );
};

export { ExternalLayout };
