import { RouteMenuLocationEnum, routes } from '@config/routes';
import { getMenuRoutes, Redirect } from '@lib/routing';
import { UserEntity } from '@modules/user/entities';

const redirectByMenuRoutes = (currentUser: UserEntity) => {
    const menuRoutes = getMenuRoutes(RouteMenuLocationEnum.sidebar, {
        forUser: currentUser,
        orderByOriented: 'lap-first',
    });

    if (menuRoutes.length === 0) {
        return <Redirect noThrow to={routes.error.path} />;
    }

    return <Redirect noThrow to={menuRoutes[0].path} />;
};

export { redirectByMenuRoutes };
