import { gql } from '@apollo/client';

import { LapAccessFragment } from '@modules/lap/graphql';

export const SchoolCollectionFragment = gql`
    fragment SchoolCollection on School {
        id
        type
        number
        shortName
        phone
        email
        currentStudentsCount
        maxStudentsCount
        studentsAddMode
        accessSentStatus
        accessSentStatusText
        region {
            id
            name
        }
        location {
            id
            name
        }
        lapAccessList {
            ...LapAccess
        }
    }
    ${LapAccessFragment}
`;
