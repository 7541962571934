import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { ExtendedFormik } from '@modules/layout/organisms';

import type { CreateLapAccessFormValues, UpdateLapAccessFormValues } from '@modules/lap/requests';

type LapAccessFormProps<T> = Omit<FormProps<T>, 'FormWrapComponent'> & {
    children?: React.ReactNode;
};

const LapAccessFormik = <T extends CreateLapAccessFormValues | UpdateLapAccessFormValues>(
    props: LapAccessFormProps<T>,
) => {
    const { initialValues, onSubmit, children } = props;

    const { t } = useTranslation(['errors']);

    return (
        <ExtendedFormik<T>
            validationSchema={yup.object({
                lap: yup.mixed().required(t('errors:validations.required') ?? ''),
            })}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {() => children}
        </ExtendedFormik>
    );
};

export { LapAccessFormik };
