import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { black, red, green } from '@avangard/ui/colors';

export type BadgetProps = {
    color: 'red' | 'green' | 'gray';
    children: React.ReactNode;
};

const colorPropertiesMap = {
    red: {
        color: red[100],
        backgroundColor: rgba(red[100], 0.08),
        border: `1px solid ${red[100]}`,
    },
    green: {
        color: green[100],
        backgroundColor: rgba(green[100], 0.08),
        border: `1px solid ${green[100]}`,
    },
    gray: {
        color: black[50],
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        border: `1px solid rgba(0, 0, 0, 0.38)`,
    },
};

const Root = styled.div<{ color: BadgetProps['color'] }>`
    display: inline-flex;
    padding: 2px 4px;
    font-size: 1.2rem;
    line-height: 16px;
    border-radius: 4px;

    ${p => colorPropertiesMap[p.color]};
`;

const Badget = (props: BadgetProps): React.ReactElement => {
    const { color = 'gray', children } = props;

    return <Root color={color}>{children}</Root>;
};

export { Badget };
