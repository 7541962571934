import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { EventNote } from '@material-ui/icons';
import { breakpoints } from '@avangard/ui/utils';
import { white } from '@avangard/ui/colors';

import { useCurrentUser } from '@modules/user/hooks';
import { useCurrentLap } from '@modules/lap/hooks';
import { ChangeLapFormModal } from '@modules/lap/organisms';
import { UserGrantCategoryToken, UserGrantActionToken } from '@modules/types/graphql';

type CurrentLapInfoProps = {
    className?: string;
};

const Title = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    font-weight: 400;

    > * {
        display: inline-flex;
    }

    > span {
        margin-inline-start: 4px;
        visibility: hidden;
        opacity: 0;
        transition: 0.2s opacity, 0.2s visibility;
    }

    > p:first-child {
        font-size: 1.2rem;
    }

    > p:last-child {
        display: inline-block;
        max-width: 110px;
        margin-inline-start: auto;
        font-size: 1.2rem;
        line-height: 16px;
        white-space: pre;
        text-overflow: ellipsis;
        overflow: hidden;

        ${breakpoints.down('sm')} {
            max-width: none;
        }
    }
`;

const Content = styled.div`
    margin-top: 4px;
    font-weight: 600;
`;

const Root = styled.div<{ interactive?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 58px;
    padding: 8px 14px;
    background-color: rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    transition: 0.2s background-color;

    ${p =>
        !!p.interactive &&
        css`
            cursor: pointer;

            &:hover {
                background-color: rgba(0, 0, 0, 0.24);

                > ${Title} > span {
                    opacity: 1;
                    visibility: visible;
                }
            }
        `}

    > * {
        width: 100%;
        color: ${white[100]};
        font-size: 1.4rem;
        line-height: 16px;
    }
`;

const calendarIcon = <EventNote style={{ fontSize: 16 }} />;

const CurrentLapInfo = (props: CurrentLapInfoProps) => {
    const { t } = useTranslation(['lap']);

    const { currentLap, loading: currentLapLoading } = useCurrentLap();

    const { currentUser } = useCurrentUser();
    const {
        changeLapGrants: { canView },
    } = currentUser.can({ [UserGrantCategoryToken.changeLap]: [UserGrantActionToken.view] });

    const disableChangeLap = !canView;

    const [openChangeLapModal, setOpenChangeLapModal] = React.useState(false);

    const handleOpenChangeLapModal = (): void => {
        if (!disableChangeLap) {
            setOpenChangeLapModal(true);
        }
    };

    const handleCloseChangeLapModal = (): void => {
        if (!disableChangeLap) {
            setOpenChangeLapModal(false);
        }
    };

    return (
        <>
            <Root interactive={!disableChangeLap} onClick={handleOpenChangeLapModal} {...props}>
                {currentLapLoading ? (
                    <CircularProgress size={32} />
                ) : (
                    <>
                        <Title>
                            <p>{t('lap:blocks.titles.lap')}</p>
                            <span>{calendarIcon}</span>
                            <p>{currentLap.course.name}</p>
                        </Title>

                        <Content>{currentLap.getFullDate()}</Content>
                    </>
                )}
            </Root>

            <ChangeLapFormModal open={openChangeLapModal} onClose={handleCloseChangeLapModal} />
        </>
    );
};

export { CurrentLapInfo };
