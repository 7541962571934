import { i18n } from '@lib/i18n';
import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import { eljurRoleTranslates } from '@modules/eljur/constants';
import type { StudentIntegrationEljur } from '@modules/types/graphql';

export type Entity = Partial<StudentIntegrationEljur> | null;

@DecribeEntity('EljurIntegrationEntity')
class EljurIntegrationEntity extends BaseEntity {
    roles: Array<string | null> | null;

    constructor(integration: Entity) {
        super(integration);

        this.roles = integration?.roles ?? null;
    }

    getTranslateRoles() {
        if (!this.roles || this.roles.length === 0) {
            return '-';
        }

        const translatedRoles = this.roles
            .map((role: string | null) => {
                return !!role && !!eljurRoleTranslates[role] && i18n.t(eljurRoleTranslates[role]);
            })
            .filter(Boolean);

        return translatedRoles.toString();
    }
}

export { EljurIntegrationEntity };
