import { gql } from '@apollo/client';

import { LapFragment } from '../fragments';

export const CreateLapMutation = gql`
    mutation CreateLap($item: LapCreateArgs!) {
        createLap(item: $item) {
            ...Lap
        }
    }
    ${LapFragment}
`;
