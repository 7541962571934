import { EntityList } from '@lib/entity';
import { VideoFileDocumentEntity } from './video-file-document.entity';

import type { Entity } from './video-file-document.entity';

class VideoFileDocumentEntityList extends EntityList<VideoFileDocumentEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(VideoFileDocumentEntity, items, count);
    }
}

export { VideoFileDocumentEntityList };
