import React from 'react';

type ExternalLayoutContextValues = {
    pageTitle: string;
    headerTitle: string;
    onSetPageTitle: (pageTitle: string) => void;
    onSetHeaderTitle: (headerTitle: string) => void;
};

type ExternalLayoutProviderProps = {
    title: string;
    children: React.ReactNode;
};

const ExternalLayoutContext = React.createContext<ExternalLayoutContextValues | undefined>(
    undefined,
);

// TODO: Придумать механизм объединения общих пропсов для всех лаяутов, чтобы не дублировать их в каждом лаяуте
const ExternalLayoutProvider = (props: ExternalLayoutProviderProps): React.ReactElement => {
    const { title, children } = props;

    const [pageTitle, setPageTitle] =
        React.useState<ExternalLayoutContextValues['pageTitle']>(title);

    const [headerTitle, setHeaderTitle] =
        React.useState<ExternalLayoutContextValues['headerTitle']>(title);

    React.useEffect(() => {
        setPageTitle(title);
        setHeaderTitle(title);
    }, [title]);

    React.useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

    const onSetPageTitle = React.useCallback((newPageTitle: string) => {
        setPageTitle(newPageTitle);
    }, []);

    const onSetHeaderTitle = React.useCallback((newHeaderTitle: string) => {
        setHeaderTitle(newHeaderTitle);
    }, []);

    const memoizedValue: ExternalLayoutContextValues = React.useMemo(
        () => ({
            pageTitle,
            headerTitle,
            onSetPageTitle,
            onSetHeaderTitle,
        }),
        [pageTitle, headerTitle, onSetPageTitle, onSetHeaderTitle],
    );

    return (
        <ExternalLayoutContext.Provider value={memoizedValue}>
            {children}
        </ExternalLayoutContext.Provider>
    );
};

const useExternalLayout = (): ExternalLayoutContextValues => {
    const context = React.useContext(ExternalLayoutContext);

    if (context === undefined) {
        throw new Error('external layout context value are undefined');
    }

    return context;
};

export { ExternalLayoutContext, ExternalLayoutProvider, useExternalLayout };
