import { endOfDay, format } from 'date-fns';

import type { CourseEntity } from '@modules/courses/entities';
import type { LapUpdateArgs } from '@modules/types/graphql';

export type UpdateLapFormValues = {
    course?: CourseEntity | null;
    from: Date | null;
    to: Date | null;
    isFree: boolean;
    canCertificate: boolean;
};

class UpdateLapRequest {
    from: LapUpdateArgs['from'];
    to: LapUpdateArgs['to'];
    isFree: LapUpdateArgs['isFree'];
    canCertificate: LapUpdateArgs['canCertificate'];

    constructor(values: UpdateLapFormValues) {
        this.from = values.from ? format(endOfDay(values.from), 'yyyy-MM-dd') : '';
        this.to = values.to ? format(endOfDay(values.to), 'yyyy-MM-dd') : '';
        this.isFree = values.isFree;
        this.canCertificate = values.canCertificate;
    }
}

export { UpdateLapRequest };
