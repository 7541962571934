import { ArrayHelper } from '@helpers';

import { $editGrantsStore, addGrantEvent, enableEditGrantsEvent, resetEditGrantsEvent } from './';

$editGrantsStore
    .on(addGrantEvent, (state, payload) => {
        let grants = [...state.grants];

        const idx = grants.findIndex(
            grant =>
                grant.id === payload.id &&
                grant.category === payload.category &&
                grant.action === payload.action,
        );

        if (idx > -1) {
            grants = ArrayHelper.replace(grants, payload, idx);
        } else {
            grants = ArrayHelper.add(grants, payload);
        }

        return {
            ...state,
            grants,
        };
    })
    .on(enableEditGrantsEvent, state => ({ ...state, enabled: true }))
    .reset(resetEditGrantsEvent);
