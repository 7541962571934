import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { LapIntegrations } from '@modules/types/graphql';

export type Entity = Partial<LapIntegrations> | null;

@DecribeEntity('LapIntegrationsEntity')
class LapIntegrationsEntity extends BaseEntity {
    eljur: boolean;

    constructor(lapIntegration: Entity) {
        super(lapIntegration);

        this.eljur = lapIntegration?.eljur ?? false;
    }
}

export { LapIntegrationsEntity };
