import dot from 'dot-object';

import type { TableFiltersQuery } from '@modules/layout/hooks';
import type { Sort, PageArgs } from '@modules/types/graphql';

class CollectionRequest {
    page: PageArgs;
    orderBy?: Record<string, any>;

    constructor(values: TableFiltersQuery['filters']) {
        this.page = {
            page: values.page,
            size: values.perPage,
        };

        let orderDirection: Sort | Record<string, any> | undefined = values.orderDirection;
        let orderByWith;

        if (values.orderField) {
            const orderField = values.orderField;

            if (Array.isArray(orderField)) {
                const key = orderField.join('.');

                const dotObject = {
                    [key]: orderDirection,
                };

                orderDirection = dot.object(dotObject);
            } else {
                orderDirection = {
                    [orderField]: values.orderDirection,
                };
            }
        }

        if (values.orderByWith) {
            orderByWith = JSON.parse(values.orderByWith);
        }

        this.orderBy = values.orderBy
            ? {
                  [values.orderBy]: orderDirection,
                  ...orderByWith,
              }
            : undefined;
    }
}

export { CollectionRequest };
